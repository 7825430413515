import { Controller } from "stimulus";

export default class extends Controller {
  static values = { target: String };

  toggle() {
    this.targetElement().classList.toggle("hidden");
  }

  targetElement() {
    return document.querySelector(this.targetValue);
  }
}

import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import t from "../lib/translate";
import Loader from "./Loader";
import TimeBarChart from "./TimeBarChart";

const FacilitiesPageAnalysis = ({ filters, analysis }) => {
  const [activeTab, setActiveTab] = useState("by_action_type");

  if (!analysis) {
    return <Loader />;
  }

  const groupedActionTypeCosts = analysis.action_type;
  const groupedFacilityCosts = analysis.yearly_facility_costs;
  const groupedTagCosts = analysis.facility_tag_costs;

  const assignLabelAndDataToObject = (item) =>
    Object.assign({}, item, {
      label: item.name,
      data: item.costs,
    });

  const yearlyActionTypeChartData = groupedActionTypeCosts.map(
    assignLabelAndDataToObject
  );
  const yearlyChartData = groupedFacilityCosts.map(assignLabelAndDataToObject);
  const yearlyTagChartData = groupedTagCosts.map(assignLabelAndDataToObject);

  const tabs = [
    {
      key: "by_action_type",
      label: t("Utgiftstyp och år"),
      component: (
        <div data-cy="graph-organization-by-action-type">
          <TimeBarChart
            series={yearlyActionTypeChartData}
            reverseStackingOrder={true}
          />
        </div>
      ),
    },
    {
      key: "by_facilities",
      label: t("Fastighet och år"),
      component: (
        <div data-cy="graph-organization-by-facilities">
          <TimeBarChart series={yearlyChartData} reverseStackingOrder={true} />
        </div>
      ),
    },
    {
      key: "by_tags",
      label: t("Tagg och år"),
      component: (
        <div data-cy="graph-organization-by-tags">
          <TimeBarChart stacked={false} series={yearlyTagChartData} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex flex-wrap gap-40 align-items-center flex-jc-spb">
        <div className="flex align-items-center">
          <p className="mb-0 mr-8 fw-medium">{t("Välj graf:")}</p>
          <Dropdown>
            <Dropdown.Toggle
              className="react-dropdownbtn"
              variant=""
              id="dropdown-basic"
            >
              {tabs.find((tab) => tab.key === activeTab).label}
            </Dropdown.Toggle>
            <Dropdown.Menu className="react-dropdownmenu">
              {tabs.map((tab) => (
                <Dropdown.Item
                  as="li"
                  key={tab.key}
                  onSelect={() => setActiveTab(tab.key)}
                  active={activeTab === tab.key}
                >
                  {t(tab.label)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="flex gap-8">
          <ExportButton type="pdf" filters={filters}>
            <i className="fa fa-file-pdf"></i> {t("Exportera PDF")}
          </ExportButton>
          <ExportButton type="excel" filters={filters}>
            <i className="fa fa-file-excel"></i> {t("Exportera Excel")}
          </ExportButton>
        </div>
      </div>
      <div data-cy={`graph-organization-${activeTab}`}>
        {tabs.find((tab) => tab.key === activeTab).component}
      </div>
    </>
  );
};

const ExportButton = ({ type, filters, children }) => (
  <form action="/facilities/export" acceptCharset="UTF-8" method="post">
    <input type="hidden" name="authenticity_token" value={window.authToken()} />
    <input type="hidden" name="type" value={type} />
    {(filters.organizations || []).map((value) => (
      <input type="hidden" name="organizations[]" key={value} value={value} />
    ))}
    {(filters.facility_tags || []).map((value) => (
      <input type="hidden" name="facility_tags[]" key={value} value={value} />
    ))}
    {(filters.region || []).map((value) => (
      <input type="hidden" name="region[]" key={value} value={value} />
    ))}
    <button className="btn btn-secondary" type="submit">
      {children}
    </button>
  </form>
);

export default FacilitiesPageAnalysis;

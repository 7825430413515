import { useState, useEffect } from "react";

const useBooleanSwitch = (currentValue, { onCallback, offCallback }) => {
  const [previousValue, setPreviousValue] = useState(false);

  useEffect(() => {
    if (previousValue && !currentValue) {
      if (offCallback) {
        offCallback();
      }
      setPreviousValue(false);
    } else if (!previousValue && currentValue) {
      if (onCallback) {
        onCallback();
      }
      setPreviousValue(true);
    }
  }, [currentValue, previousValue, onCallback, offCallback]);
};

export default useBooleanSwitch;

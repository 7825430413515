import { Controller } from "stimulus";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  static values = { debounceTime: Number };

  update(event) {
    if (this.hasDebounceTimeValue) {
      this.submit = debounce(this.submit, this.debounceTimeValue).bind(this);
    }

    event.preventDefault();
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    if (searchParams.get("date_period") == event.target.value) {
      searchParams.delete("date_period");
      url.search = searchParams;
      PageLoader.load(url, PageLoader.Action.REPLACE);
      return;
    }

    event.target.closest("form").requestSubmit();
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    const count = this.checkboxTargets.filter((box) => box.checked).length;

    if (count > 0) {
      const categoryId = this.checkboxTargets.find((box) => box.checked).dataset
        .checkboxCountCategoryIdParam;
      const countForCategorySpan = document.querySelector(
        `[data-category-id='${categoryId}']`
      );

      this.updateCount(countForCategorySpan, count);
    }
  }

  updateCountForCategory(e) {
    const count = this.checkboxTargets.filter((box) => box.checked).length;
    const categoryId = e.target.dataset.checkboxCountCategoryIdParam;
    const countForCategorySpan = document.querySelector(
      `[data-category-id='${categoryId}']`
    );

    this.updateCount(countForCategorySpan, count);
    this.updateClass(countForCategorySpan.closest("li"), count > 0);
  }

  updateCount(target, count) {
    target.innerText = count == 0 ? "" : `(${count})`;
  }

  updateClass(listItem, hasOptions) {
    if (hasOptions) {
      listItem.classList.add("has-options");
    } else {
      listItem.classList.remove("has-options");
    }
  }
}

import { Controller } from "stimulus";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  update(event) {
    event.preventDefault();
    this.disconnectSelectControllers();
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    const formData = new FormData(this.element);
    for (let key of formData.keys()) {
      searchParams.delete(key);
    }

    for (let param of formData.entries()) {
      searchParams.append(param[0], param[1]);
    }
    url.search = searchParams;

    PageLoader.load(url, PageLoader.Action.REPLACE);
  }

  disconnectSelectControllers() {
    const elements = document.querySelectorAll("[data-controller*='select']");

    elements.forEach((element) => {
      element.dataset.controller = element.dataset.controller
        .split(" ")
        .filter((controller) => {
          return controller !== "select";
        })
        .join(" ");
    });
  }
}

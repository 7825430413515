// This function does not translate anything, but is useful for locating
// strings that should be translated in the future.
export default (text, variables = {}) => {
  if (Object.keys(variables).length === 0) {
    return text;
  }

  return Object.keys(variables).reduce(
    (output, key) =>
      output.replace(new RegExp(`{:${key}}`, "g"), variables[key]),
    text
  );
};

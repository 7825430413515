import { Controller } from "stimulus";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  connect() {
    window.addEventListener("popstate", this.load);
  }

  disconnect() {
    window.removeEventListener("popstate", this.load);
  }

  load() {
    PageLoader.load(document.location, PageLoader.Action.POP);
  }
}

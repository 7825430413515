import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["maps"];
  static values = {
    facilities: Array,
    params: Object,
  };

  initialize() {
    this.activeWindow = null;
  }

  async initMap() {
    const { Map } = await google.maps.importLibrary("maps");

    const mapOptions = {
      zoom: 6,
      center: new google.maps.LatLng(57.7041, 11.9649),
      mapId: "7af3aca158640605",
    };

    this.map = new Map(this.mapsTarget, mapOptions);

    const bounds = new google.maps.LatLngBounds();
    this.facilitiesValue.forEach((facility) => {
      bounds.extend(new google.maps.LatLng(facility.lat, facility.long));
      this.createMarker(facility);
    });

    const MAX_ZOOM = 17;
    if (this.facilitiesValue.length > 0) {
      google.maps.event.addListenerOnce(this.map, "bounds_changed", () => {
        this.map.setZoom(Math.min(this.map.getZoom(), MAX_ZOOM));
      });
      this.map.fitBounds(bounds);
    }
  }

  async createMarker(facilityData) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    const planimaFlagImg = document.createElement("img");

    planimaFlagImg.src =
      "https://planima-public.s3.eu-west-1.amazonaws.com/pictures/pin-planima-coral_60x77px.png";

    const markerOptions = {
      position: new google.maps.LatLng(facilityData.lat, facilityData.long),
      map: this.map,
      title: facilityData.name,
      content: planimaFlagImg,
      gmpClickable: true,
    };

    const googleMarker = new AdvancedMarkerElement(markerOptions);

    const infoWindowContent = `
      <div class="custom-info-window">
        <p class="mt-0">
          <a href="/facilities/${facilityData.id}">
            <strong>${facilityData.name}</strong>
          </a>
        </p>
        <p>
          ${facilityData.address}<br />
          ${facilityData.zip_code} ${facilityData.region}
        </p>
      </div>
    `;

    const infoWindow = new google.maps.InfoWindow({
      content: infoWindowContent,
    });

    googleMarker.addListener("click", () => {
      if (this.activeWindow) {
        this.activeWindow.close();
      }
      infoWindow.open(this.map, googleMarker);
      this.activeWindow = infoWindow;
    });
  }

  paramsValueChanged() {
    this.initMap();
  }
}

import React from "react";
import debounce from "lodash.debounce";
import API from "../lib/API";

const ASC = 0;
const DESC = 1;

class ListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      filter: props.initialFilter || "",
      perPage: 10,
      page: 1,
      sorting: { field: "name", direction: ASC },
      isLoading: false,
    };

    this.loadData = this.loadData.bind(this);
    this.debouncedLoadData = debounce(this.loadData, 250);
  }

  async componentDidMount() {
    this.loadData();
  }

  async loadData() {
    if (!this.props.shouldLoadData(this.props)) {
      return;
    }

    this.setState({ isLoading: true });

    const requestSent = Date.now();
    this._lastLoadRequest = requestSent;

    const response = await API.get(this.props.endpoint, {
      query: this.getQuery(),
    });
    const data = await response.json();
    if (this._lastLoadRequest == requestSent) {
      this.setState({ data, isLoading: false });
    }
  }

  getQuery() {
    const { perPage, page, sorting, filter } = this.state;
    const offset = perPage * (page - 1);

    const baseQuery = this.props.baseQuery || {};
    const query = Object.assign({}, baseQuery, {
      page: {
        limit: perPage,
        offset: offset,
      },
    });
    if (sorting) {
      query["sort"] = `${sorting.direction === DESC ? "-" : ""}${
        sorting.field
      }`;
    }
    if (filter) {
      query["filter"] = {
        query: filter,
        fields: "name",
      };
    }

    return query;
  }

  onPerPageChange(perPage) {
    this.setState({ perPage, page: 1 }, this.debouncedLoadData);
  }

  onPageChange(page) {
    this.setState({ page }, this.debouncedLoadData);
  }

  onFilterChange(filter) {
    this.setState({ filter, page: 1 }, this.debouncedLoadData);
  }

  onSortChange(field) {
    if (!this.state.sorting || this.state.sorting.field !== field) {
      this.setState(
        { sorting: { field, direction: ASC } },
        this.debouncedLoadData
      );
      return;
    }

    this.setState(
      {
        sorting: {
          field,
          direction: this.state.sorting.direction === ASC ? DESC : ASC,
        },
      },
      this.debouncedLoadData
    );
  }

  getItems() {
    if (!this.state.data || !this.state.data.data) {
      return [];
    }

    return this.props.getItemsFromData(this.state.data.data);
  }

  render() {
    const { filter, page, perPage, data, sorting, isLoading } = this.state;
    const ListComponent = this.props.component;

    return (
      <ListComponent
        items={this.getItems()}
        filter={filter}
        total={data ? data.meta.total_count : 0}
        perPage={perPage}
        page={page}
        sorting={sorting}
        isLoading={isLoading}
        onSortChange={this.onSortChange.bind(this)}
        onPerPageChange={this.onPerPageChange.bind(this)}
        onPageChange={this.onPageChange.bind(this)}
        onFilterChange={this.onFilterChange.bind(this)}
        onSelect={this.props.onSelect}
      />
    );
  }
}

export default ListContainer;

import { Controller } from "stimulus";
import API from "../lib/API";

export default class extends Controller {
  static targets = ["totalCosts", "actionTypes", "checkbox"];

  toggle() {
    API.patch("/user_settings", {
      body: {
        key: "show_action_type_shares",
        value: this.checkboxTarget.checked,
      },
    });

    this.switchTables(this.checkboxTarget.checked);
  }

  switchTables(showActionTypes) {
    if (showActionTypes) {
      this.actionTypesTarget.classList.remove("hidden");
      this.totalCostsTarget.classList.add("hidden");
    } else {
      this.actionTypesTarget.classList.add("hidden");
      this.totalCostsTarget.classList.remove("hidden");
    }
  }
}

import API from "../lib/API";

const update = async (id, attributes) => {
  const response = await API.patch(`maintenance_plans/${id}`, {
    body: {
      data: {
        attributes: attributes,
      },
    },
  });
  return await response.json();
};

export default { update };

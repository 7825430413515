import React, { useState } from "react";

import t from "../lib/translate";
import UIBox from "./UIBox";

const DepositionSettings = (props) => {
  const { isReadOnly, plan } = props;
  const [altYearlyDeposition, setAltYearlyDeposition] = useState(
    plan.attributes.alt_yearly_deposition
  );
  const [yearlyDeposition, setYearlyDeposition] = useState(
    plan.attributes.yearly_deposition
  );
  const [startBalance, setStartBalance] = useState(
    plan.attributes.start_balance
  );

  const onSubmit = (event, attributes) => {
    event.preventDefault();
    props.onChange(attributes);
  };

  let recommendedDepositionTitle = t("Rekommenderad avsättning per år");
  if (!isReadOnly) {
    recommendedDepositionTitle += "<sup>*</sup>";
  }
  return (
    <div data-cy="deposition-settings">
      <div className="row">
        <UIBox cols={6} heading={recommendedDepositionTitle} row={false}>
          <form
            role="form"
            onSubmit={(event) => {
              onSubmit(event, {
                alt_yearly_deposition: altYearlyDeposition,
              });
            }}
          >
            <div className="form-group">
              {isReadOnly && (
                <p style={{ fontSize: "28px" }}>
                  {plan.attributes.alt_yearly_deposition} {t("kr")}
                </p>
              )}
              {!isReadOnly && (
                <div className="input-group">
                  <span className="input-group-addon">{t("kr")}</span>
                  <input
                    type="text"
                    className="form-control"
                    value={altYearlyDeposition}
                    onChange={(event) =>
                      setAltYearlyDeposition(event.target.value)
                    }
                    placeholder={t("Rekommenderad avsättning")}
                  />
                </div>
              )}
              <br />
              {!isReadOnly && (
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={t("Uppdatera")}
                />
              )}

              {!isReadOnly && (
                <p style={{ marginTop: "10px" }}>
                  <sup>*</sup>{" "}
                  {t(
                    "Detta värde sätts manuellt, vanligtvis av den som levererar underhållsplanen. Om ni inte har en leverantör så kan ni antingen ange ett eget avsättningsmål eller bortse från detta fält."
                  )}
                </p>
              )}
            </div>
          </form>
        </UIBox>
        <UIBox cols={6} heading={t("Nuvarande avsättning per år")}>
          <form
            role="form"
            onSubmit={(event) => {
              onSubmit(event, {
                yearly_deposition: yearlyDeposition,
              });
            }}
          >
            <div className="form-group">
              {isReadOnly && (
                <p style={{ fontSize: "28px" }}>
                  {plan.attributes.yearly_deposition} {t("kr")}
                </p>
              )}
              {!isReadOnly && (
                <div className="input-group">
                  <span className="input-group-addon">{t("kr")}</span>
                  <input
                    type="text"
                    className="form-control"
                    value={yearlyDeposition}
                    onChange={(event) =>
                      setYearlyDeposition(event.target.value)
                    }
                    placeholder={t("Nuvarande avsättning")}
                  />
                </div>
              )}
              <br />
              {!isReadOnly && (
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={t("Uppdatera")}
                />
              )}
            </div>
          </form>
        </UIBox>
      </div>

      <form
        role="form"
        onSubmit={(event) => {
          onSubmit(event, { start_balance: startBalance });
        }}
      >
        <UIBox
          cols={6}
          heading={t("Saldo i underhållsfond vid inledningen av år {:year}", {
            year: plan.attributes.start_year,
          })}
          row={true}
        >
          <div className="form-group">
            {isReadOnly && (
              <p style={{ fontSize: "28px" }}>
                {plan.attributes.start_balance} {t("kr")}
              </p>
            )}

            {!isReadOnly && (
              <>
                <div className="input-group">
                  <span className="input-group-addon">{t("kr")}</span>
                  <input
                    type="text"
                    className="form-control"
                    value={startBalance}
                    onChange={(event) => setStartBalance(event.target.value)}
                    placeholder={t("Saldo")}
                  />
                </div>
                <br />
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={t("Uppdatera")}
                />
              </>
            )}
          </div>
        </UIBox>
      </form>
    </div>
  );
};

export default DepositionSettings;

import { Controller } from "stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["input", "results", "selectable"];
  static values = { index: Number, default: -1 };

  initialize() {
    this.search = debounce(this.search, 300).bind(this);
  }

  connect() {
    document.addEventListener("keydown", this.onKeyDown.bind(this));
    document.addEventListener("mousedown", this.preventBlurOnDropdownClick);
    this.inputTarget.addEventListener("input", this.search.bind(this));
    this.inputTarget.addEventListener("focus", this.search.bind(this));
    this.inputTarget.addEventListener("blur", this.hideResults.bind(this));
  }

  disconnect() {
    document.removeEventListener("keydown", this.onKeyDown.bind(this));
    document.removeEventListener("mousedown", this.preventBlurOnDropdownClick);
    this.inputTarget.removeEventListener("input", this.search.bind(this));
    this.inputTarget.removeEventListener("focus", this.search.bind(this));
    this.inputTarget.removeEventListener("blur", this.hideResults.bind(this));
  }

  preventBlurOnDropdownClick = (e) => {
    // Prevent clicks within the result area from blurring the input field,
    // which closes the results and any click on a result row will not be
    // triggered.
    if (this.resultsTarget.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  onKeyDown(e) {
    if (e.key === "ArrowDown") {
      this.changeIndexBy(1);
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      this.changeIndexBy(-1);
      e.preventDefault();
    } else if (e.key === "Escape") {
      this.hideResults();
    } else if (e.key === "Enter") {
      this.triggerSelectedItem(e);
    }
  }

  async search(path, query) {
    this.resetSelectableTargetsIndex();

    const response = await fetch(`${path}?query=` + encodeURIComponent(query), {
      redirect: "manual",
    });
    if (response.status === 200) {
      const results = await response.text();
      this.resultsTarget.innerHTML = results;
    } else {
      this.resultsTarget.innerHTML =
        "Någonting gick fel! Ladda om sidan och försök igen.";
    }
    this.showResults();
  }

  changeIndexBy(delta) {
    let newIndex = this.indexValue + delta;
    if (newIndex >= this.selectableTargets.length) {
      newIndex = -1;
    } else if (newIndex < -1) {
      newIndex = this.selectableTargets.length - 1;
    }

    this.indexValue = newIndex;
  }

  indexValueChanged() {
    this.selectableTargets.forEach((el, index) => {
      if (index !== this.indexValue) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
      }
    });
  }

  triggerSelectedItem(e) {
    if (this.indexValue == -1) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    this.selectableTargets[this.indexValue].click();
  }

  resetSelectableTargetsIndex() {
    this.indexValue = -1;
  }

  select(e) {
    this.onSelect(e.params.item);
  }

  showResults() {
    if (this.inputTarget !== document.activeElement) {
      return;
    }
    this.resultsTarget.classList.remove("hidden");
  }

  hideResults() {
    this.resultsTarget.classList.add("hidden");
  }
}

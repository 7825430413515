import { Controller } from "stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["detail"];

  connect() {
    this.details = this.detailTargets;

    this.details.forEach((detail) => {
      detail.addEventListener("toggle", this.debouncedStoreOpenState);
    });

    this.updateToggleState = debounce(this.updateToggleState.bind(this), 300);
    this.updateToggleState();
    window.addEventListener("resize", this.updateToggleState);
  }

  disconnect() {
    this.details.forEach((detail) => {
      detail.removeEventListener("toggle", this.debouncedStoreOpenState);
    });
    window.removeEventListener("resize", this.updateToggleState);
  }

  updateToggleState() {
    if (window.innerWidth < 480) {
      this.details.forEach((detail) => {
        detail.removeAttribute("open");
      });
    } else {
      this.applyOpenStates();
    }
  }

  debouncedStoreOpenState = debounce(() => {
    if (window.innerWidth >= 480) {
      this.storeOpenState();
    }
  }, 300);

  storeOpenState() {
    localStorage.setItem(
      "subCategoryStates",
      JSON.stringify(this.getOpenStates())
    );
  }

  getOpenStates() {
    const openStates = {};

    this.details.forEach((detail) => {
      const [category_key, subcategory_key] =
        detail.dataset.toggleDetailsTargetIdValue.split("-");
      const value = detail.hasAttribute("open");

      if (!openStates[category_key]) {
        openStates[category_key] = {};
      }

      openStates[category_key][subcategory_key] = value;
    });

    return openStates;
  }

  applyOpenStates() {
    const openStates =
      JSON.parse(localStorage.getItem("subCategoryStates")) || {};

    this.details.forEach((detail) => {
      const [category_key, subcategory_key] =
        detail.dataset.toggleDetailsTargetIdValue.split("-");
      const isOpen =
        openStates[category_key] && openStates[category_key][subcategory_key];

      if (isOpen || isOpen === undefined) {
        detail.setAttribute("open", true);
      } else {
        detail.removeAttribute("open");
      }
    });
  }
}

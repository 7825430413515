import React, { useState } from "react";
import { array, bool, number } from "prop-types";
import { Dropdown } from "react-bootstrap";
import AnalysisPieChart from "./AnalysisPieChart";
import KPIBox from "./KPIBox";
import Tooltip from "./Tooltip";
import { currency } from "../lib/helpers";
import t from "../lib/translate";
import { getColor, getChartColorsFromStatuses } from "../lib/chartHelpers";
import TimeBarChart from "./TimeBarChart";
import UIBox from "./UIBox";
import colors from "../../assets/stylesheets/colors.scss";

const AnalysisPage = ({
  allowMultiplePlans,
  buildingShare,
  categoryShare,
  costPerYearAndArea,
  statusShare,
  facilityShare,
  yearlyAverageCost,
  yearlyCosts,
  yearlyCostsByActionType,
  totalCost,
  maintenanceDebt,
  averageMaintenanceDebtPerArea,
  benchmarkMaintenanceDebtPerArea,
  investmentCost,
  maintenanceCost,
}) => {
  const showBuldings = buildingShare.length > 1;

  const hasArea = costPerYearAndArea || averageMaintenanceDebtPerArea;
  const getTooltipText = (base) => {
    if (!hasArea) {
      return base;
    }

    const facilityString = allowMultiplePlans
      ? t("fastigheternas")
      : t("fastighetens");
    return `${base}. ${t(
      "För beräkningar baserade på m² används {:facilityString} sammanlagda yta (BOA+LOA).",
      { facilityString }
    )}`;
  };

  const preferredActionTypeColors = {
    Underhåll: getColor(0),
    Investering: getColor(1),
  };

  // Pass colors used by category share pie chart to yearly chart
  // to synchronize the category colors between them
  const preferredCategoryColors = getPreferredColors(
    categoryShare,
    yearlyCosts
  );

  const [activeTab, setActiveTab] = useState("by_action_type");

  const tabs = [
    {
      key: "by_action_type",
      label: t("Utgiftstyp och år"),
      component: (
        <div id="graph-yearly">
          <TimeBarChart
            series={yearlyCostsByActionType}
            preferredColors={preferredActionTypeColors}
          />
        </div>
      ),
    },
    {
      key: "by_category",
      label: t("Kategori och år"),
      component: (
        <div id="graph-yearly">
          <TimeBarChart
            series={yearlyCosts}
            preferredColors={preferredCategoryColors}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <UIBox>
        <ChartSelector
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <h3 className="flex flex-jc-center">{t("Utgifter per år")}</h3>

        <div data-cy={`graph-actions-${activeTab}`}>
          {tabs.find((tab) => tab.key === activeTab).component}
        </div>
      </UIBox>

      <div className="row flex align-items-stretch flex-wrap">
        <div className="col-md-6 flex flex-grow-1">
          <KPIBox
            title={t("Utgift")}
            tooltip={getTooltipText(t("Utgift för alla valda åtgärder"))}
            extras={[
              <>
                <strong>{currency(yearlyAverageCost, true)}</strong>{" "}
                {t("per år")}
              </>,
              costPerYearAndArea && (
                <>
                  <strong>{currency(costPerYearAndArea, false)}</strong>{" "}
                  {t("per år och m²")}{" "}
                </>
              ),
            ]}
          >
            <h1>{currency(totalCost, true)}</h1>

            <div className="flex align-items-center font-18">
              <span className="ml-16 mr-8 label label-light-green min-w-80">
                {t("Investering")}
              </span>
              {currency(investmentCost, true)}
            </div>
            <div className="flex align-items-center mb-16 font-18">
              <span className="ml-16 mr-8 label label-light-orange min-w-80">
                {t("Underhåll")}
              </span>
              {currency(maintenanceCost, true)}
            </div>
          </KPIBox>
        </div>
        <div className="col-md-6 flex flex-grow-1">
          <KPIBox
            title={t("Underhållsskuld")}
            tooltip={getTooltipText(
              t(
                "Utgifter för underhållsåtgärder som är eftersatta, akuta samt ej genomförda åtgärder tidigare än i år"
              )
            )}
            extras={
              hasArea
                ? [
                    <>
                      <MaintenanceDebtBenchmarkIndicator
                        current={averageMaintenanceDebtPerArea}
                        benchmark={benchmarkMaintenanceDebtPerArea}
                      />
                      <strong>
                        {currency(averageMaintenanceDebtPerArea, false)}
                      </strong>{" "}
                      {t("per m²")}
                    </>,
                  ]
                : []
            }
          >
            <h1>{currency(maintenanceDebt, true)}</h1>
          </KPIBox>
        </div>
      </div>

      <ChartRow
        {...{
          facilityShare,
          categoryShare,
          buildingShare,
          statusShare,
          showBuldings,
          allowMultiplePlans,
        }}
      />
    </>
  );
};

const ChartSelector = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="flex align-items-center">
      <p className="mb-0 mr-16 fw-medium">{t("Välj graf:")}</p>
      <Dropdown>
        <Dropdown.Toggle
          className="react-dropdownbtn"
          variant=""
          id="dropdown-basic"
        >
          {tabs.find((tab) => tab.key === activeTab).label}
        </Dropdown.Toggle>
        <Dropdown.Menu className="react-dropdownmenu">
          {tabs.map((tab) => (
            <Dropdown.Item
              as="li"
              key={tab.key}
              onSelect={() => setActiveTab(tab.key)}
              active={activeTab === tab.key}
            >
              {t(tab.label)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const ChartRow = ({
  facilityShare,
  categoryShare,
  buildingShare,
  statusShare,
  showBuldings,
  allowMultiplePlans,
}) => {
  const charts = [
    {
      key: "facility",
      series: facilityShare,
      name: t("fastighet"),
      display: allowMultiplePlans,
    },
    {
      key: "category",
      series: categoryShare,
      name: t("kategori"),
      display: true,
    },
    {
      key: "building",
      series: buildingShare,
      name: t("byggnad"),
      display: showBuldings && !allowMultiplePlans,
    },
    {
      key: "status",
      series: statusShare,
      name: t("status"),
      display: true,
      colors: getChartColorsFromStatuses(statusShare.map((item) => item[0])),
    },
  ].filter((chart) => chart.display);

  return (
    <div className="row">
      {charts.map((chart) => (
        <AnalysisPieChart
          series={chart.series}
          name={chart.name}
          cols={12 / charts.length}
          colors={chart.colors}
          key={chart.key}
        />
      ))}
    </div>
  );
};

const MaintenanceDebtBenchmarkIndicator = ({ current, benchmark }) => {
  let baseTooltip = t(
    "En genomsnittlig fastighet i Planima har {:benchmark} per m² i underhållsskuld.",
    { benchmark: currency(benchmark, false) }
  );
  const ratio = Math.round((100 * current) / benchmark) / 100;
  let comparisonTooltip = null;
  if (ratio > 1) {
    comparisonTooltip = t("Detta värde är {:percentage}% högre.", {
      percentage: Math.round((ratio - 1) * 100),
    });
  } else if (ratio < 1) {
    comparisonTooltip = t("Detta värde är {:percentage}% lägre.", {
      percentage: Math.round((1 - ratio) * 100),
    });
  }

  let color = colors.yellow;
  if (current > benchmark * 1.2) {
    color = colors.red;
  } else if (current < benchmark * 0.8) {
    color = colors.green;
  }

  const tooltip = `${baseTooltip} ${comparisonTooltip || ""}`.trim();
  return (
    <Tooltip
      icon="fa-circle"
      text={tooltip}
      style={{ color, margin: 0, marginRight: 5 }}
    />
  );
};

const getPreferredColors = (categoryShare, yearlyCosts) => {
  const pieChartCategories = categoryShare
    .map((item) => item[0])
    .filter((item) => item !== "Övrigt");
  const barChartCategories = yearlyCosts.map((item) => item.label);
  const uniqueCategories = [
    ...new Set(pieChartCategories.concat(barChartCategories)),
  ];

  let preferredColors = {};
  uniqueCategories.forEach((label, index) => {
    preferredColors[label] = getColor(index);
  });

  return preferredColors;
};

export default AnalysisPage;

AnalysisPage.propTypes = {
  allowMultiplePlans: bool.isRequired,
  buildingShare: array.isRequired,
  categoryShare: array.isRequired,
  costPerYearAndArea: number,
  statusShare: array.isRequired,
  yearlyAverageCost: number.isRequired,
  yearlyCosts: array.isRequired,
};

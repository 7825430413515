import React from "react";
import FacilityPartList from "./FacilityPartList";
import ListContainer from "./ListContainer";

const FacilityPartListContainer = (props) => {
  return (
    <ListContainer
      {...props}
      endpoint={"/facility_parts"}
      baseQuery={{
        facility_id: props.facility,
        maintenance_plan_id: props.maintenancePlan,
      }}
      shouldLoadData={(props) =>
        Boolean(props.facility || props.maintenancePlan)
      }
      component={FacilityPartList}
      getItemsFromData={(data) =>
        data.map((item) => Object.assign({ id: item.id }, item.attributes))
      }
    />
  );
};

export default FacilityPartListContainer;

import React from "react";
import FacilityPartListContainer from "./FacilityPartListContainer";
import Modal from "./Modal";

import t from "../lib/translate";

const FacilityPartModal = (props) => {
  return (
    <Modal
      title={t("Mängdförteckning")}
      onClose={props.onClose}
      includeDialog={false}
      closeLabel={t("Tillbaka")}
    >
      <FacilityPartListContainer
        onSelect={props.onSelect}
        facility={props.facilityId}
        maintenancePlan={props.maintenancePlanId}
      />
    </Modal>
  );
};

export default FacilityPartModal;

import React from "react";
import ModalDialog from "react-bootstrap/ModalDialog";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";

import t from "../lib/translate";

const Modal = ({
  title,
  children,
  buttons = null,
  onClose,
  includeDialog = true,
  closeLabel,
}) => {
  const modalContent = (
    <React.Fragment>
      <ModalHeader>
        <ModalTitle as="h4" id="myModalLabel">
          {title}
        </ModalTitle>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">{closeLabel || t("Stäng")}</span>
        </button>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          {closeLabel || t("Stäng")}
        </button>
        {buttons}
      </ModalFooter>
    </React.Fragment>
  );

  if (includeDialog) {
    return <ModalDialog>{modalContent}</ModalDialog>;
  }
  return modalContent;
};

export default Modal;

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "displayRemaining", "toggleMore"];
  static values = { limit: Number };

  connect() {
    this.itemTargets.slice(0, this.limitValue).forEach((item) => {
      item.classList.remove("hidden");
    });

    if (this.itemTargets.length > this.limitValue) {
      this.toggleMoreTarget.classList.remove("hidden");
      this.displayRemainingTarget.innerText =
        this.itemTargets.length - this.limitValue;
    }
  }

  show(e) {
    e.preventDefault();

    this.itemTargets.forEach((item) => {
      item.classList.remove("hidden");
    });
    this.toggleMoreTarget.classList.add("hidden");
  }
}

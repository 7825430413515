import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let url = new URL(window.location.href);

    if (url.searchParams.get("reply")) {
      this.getFocus();
    }
  }

  getFocus() {
    this.element.focus();
  }
}

import React, { useState, useEffect, useRef } from "react";

import StatusPicker from "./StatusPicker";
import Modal from "react-bootstrap/Modal";
import CompleteActionModal from "./CompleteActionModal";

import { completeAction } from "../lib/maintenance_plan_action";
import PageLoader from "../lib/page_loader";

const RailsStatusPicker = ({ action, disabled, autoSubmit, fieldName }) => {
  const [status, setStatus] = useState(action.status);
  const formElement = useRef(null);
  const statusHasChanged = status !== action.status;
  const isCompleted = status === "completed";

  useEffect(() => {
    if (!autoSubmit || !statusHasChanged) {
      return;
    }

    if (formElement.current && !isCompleted) {
      formElement.current.closest("form").requestSubmit();
    }
  }, [statusHasChanged, isCompleted, autoSubmit]);

  return (
    <>
      <StatusPicker
        currentStatus={action.status}
        disabled={disabled}
        exclude={["unchanged", "draft"]}
        onSelect={setStatus}
      />

      <input type="hidden" name={fieldName} value={status} ref={formElement} />

      {statusHasChanged && isCompleted && (
        <Modal
          show
          backdrop="static"
          backdropClassName="am-fade"
          animation={false}
        >
          <CompleteActionModal
            hideModal={() => setStatus(action.status)}
            completeAction={async (data) => {
              await completeAction(data.id, data);
              PageLoader.load(document.location, PageLoader.Action.REPLACE);
            }}
            includeDialog={false}
            action={action}
          />
        </Modal>
      )}
    </>
  );
};

export default RailsStatusPicker;

import React from "react";

import ListItemsPerPageSelection from "./ListItemsPerPageSelection";

import t from "../lib/translate";

const ListSettings = (props) => {
  return (
    <div className="filter-wrapper filter-wrapper-stripped flex flex-jc-spb flex-wrap gap-16 mb-16">
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          maxWidth: "50%",
          marginRight: 20,
        }}
      >
        <div className="input-group full-width">
          <div className="input-group-addon">
            <i className="fa fa-search" />
          </div>
          <input
            type="search"
            className="form-control"
            aria-controls={props.ariaControls}
            value={props.filter}
            onChange={props.onFilterChange}
            placeholder={`${t("Sök")}...`}
            style={{ width: "100%", minWidth: 200 }}
          />
        </div>
      </div>

      <div>
        <ListItemsPerPageSelection
          ariaControls={props.ariaControls}
          onChange={props.onPerPageChange}
          value={props.perPage}
        />
      </div>
    </div>
  );
};

export default ListSettings;

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { fallback: Number };
  static targets = ["box", "input"];

  connect() {
    this.setFallbackValue();
    this.toggleVisibility();
  }

  setFallbackValue() {
    if (this.inputTarget.value.length == 4) {
      this.fallbackValue = this.inputTarget.value;
    }
  }

  toggleInput() {
    this.toggleValue();
    this.toggleVisibility();
    this.toggleReadOnly();

    if (this.boxTarget.checked) {
      this.inputTarget.focus();
    }
  }

  toggleValue() {
    if (!this.boxTarget.checked) {
      this.setFallbackValue();
      this.inputTarget.value = "";
    } else if (this.fallbackValue) {
      this.inputTarget.value = this.fallbackValue;
    }
  }

  toggleVisibility() {
    if (this.boxTarget.checked) {
      this.inputTarget.closest("[data-toggle]")?.classList.remove("hidden");
    } else {
      this.inputTarget.closest("[data-toggle]")?.classList.add("hidden");
    }
  }

  toggleReadOnly() {
    this.inputTarget.readOnly = !this.boxTarget.checked;
  }
}

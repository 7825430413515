import { qs } from "./helpers";

import cloneDeep from "lodash.clonedeep";

const errorHandlers = [];

const request = async (method, resourcePath, opts = {}) => {
  const url = getURL(resourcePath, opts.query);

  const response = await fetch(url, {
    method: method,
    credentials: "same-origin",
    headers: getHeaders(opts),
    body: getBody(opts.body),
  });

  if (!response?.ok) {
    for (const handler of errorHandlers) {
      await handler(response);
    }
  }

  return response;
};

const getURL = (path, query) => {
  const url = getBaseURL(path);

  if (query) {
    return `${url}?${qs(query)}`;
  }
  return url;
};

const getBaseURL = (path) => {
  if (path.match(/https?:\/\//)) {
    return path;
  }

  const BASE_PATH = "/api/internal/";
  if (path.charAt(0) === "/") {
    return `${BASE_PATH}${path.substring(1)}`;
  }
  return `${BASE_PATH}${path}`;
};

const getHeaders = (opts) => {
  let headers = Object.assign({}, opts.headers || {}, {
    "X-CSRF-TOKEN": document.querySelector("[name='csrf-token']")?.content,
  });

  if (opts.body && !opts.headers?.["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  return headers;
};

const getBody = (body) => {
  if (body && typeof body === "object") {
    return JSON.stringify(body);
  }
  return body;
};

const applyItemIncludes = (item, includes) => {
  if (!item.relationships) {
    return item;
  }
  item = Object.assign(item, { included: {} });

  for (let relationshipKey of Object.keys(item.relationships)) {
    const relation = item.relationships[relationshipKey].data;
    if (!relation) {
      // Some relationships may not be included
      continue;
    }

    if (!Array.isArray(relation)) {
      // Single object
      item.included[relationshipKey] = findIncludedResource(
        relation.id,
        relation.type,
        includes
      );
      continue;
    }

    // Array of objects
    item.included[relationshipKey] = [];
    for (let relationItem of relation) {
      item.included[relationshipKey].push(
        findIncludedResource(relationItem.id, relationItem.type, includes)
      );
    }
  }

  return item;
};

const applyIncludes = (data) => {
  let obj = cloneDeep(data);

  for (let item of obj.data) {
    applyItemIncludes(item, obj.included);
  }

  return obj;
};

const findIncludedResource = (id, type, resources) => {
  for (let resource of resources) {
    if (resource.type === type && resource.id === id) {
      return applyItemIncludes(cloneDeep(resource), resources);
    }
  }
};

const addErrorHandler = (errorHandler) => {
  errorHandlers.push(errorHandler);
};

const get = (resourcePath, opts) =>
  exportFunctions.request("GET", resourcePath, opts);
const post = (resourcePath, opts) =>
  exportFunctions.request("POST", resourcePath, opts);
const del = (resourcePath, opts) =>
  exportFunctions.request("DELETE", resourcePath, opts);
const put = (resourcePath, opts) =>
  exportFunctions.request("PUT", resourcePath, opts);
const patch = (resourcePath, opts) =>
  exportFunctions.request("PATCH", resourcePath, opts);

const exportFunctions = {
  request,
  get,
  post,
  del,
  put,
  patch,
  applyIncludes,
  addErrorHandler,
};

export default exportFunctions;

import React from "react";

const UIBox = (props) => {
  if (props.wrapper) {
    return (
      <div className="wrapper wrapper-content animated fadeIn">
        <BoxInner {...props} />
      </div>
    );
  }

  return <BoxInner {...props} />;
};

const BoxInner = (props) => (
  <React.Fragment>
    {props.show_notifications && props.notice && (
      <div className="alert alert-success" role="alert">
        {props.notice}
      </div>
    )}
    {props.show_notifications && props.flash && props.flash.error && (
      <div className="alert alert-danger" role="alert">
        {props.flash.error}
      </div>
    )}

    {!props.hasOwnProperty("row") || props.row ? (
      <div className="row">
        <BoxContent {...props} />
      </div>
    ) : (
      <BoxContent {...props} />
    )}
  </React.Fragment>
);

const BoxContent = (props) => (
  <div
    id={props.id}
    className={`col-md-${props.cols || 12} ${
      props.nopadding ? "nopadding" : ""
    }`}
  >
    <div className="ibox">
      {props.heading && (
        <div className="ibox-title">
          <h5
            dangerouslySetInnerHTML={{
              __html: props.heading,
            }}
          />
        </div>
      )}
      <div
        className={`ibox-content ${props.tight ? "tight" : ""} ${
          props.noinnerpadding ? "noinnerpadding" : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  </div>
);

export default UIBox;

import React from "react";
import debounce from "lodash.debounce";
import AttachmentList from "./AttachmentList";
import API from "../lib/API";

const ASC = 0;
const DESC = 1;

class AttachmentListContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      filter: "",
      perPage: 10,
      page: 1,
      sorting: { field: "name", direction: ASC },
      isLoading: false,
    };

    this.loadAttachments = this.loadAttachments.bind(this);
    this.debouncedLoadAttachments = debounce(this.loadAttachments, 250);
  }

  async componentDidMount() {
    this.loadAttachments();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.parentId !== prevProps.parentId) {
      this.loadAttachments();
    }
  }

  async loadAttachments() {
    this.setState({ isLoading: true });

    if (!this.props.parentId) {
      return;
    }

    const { perPage, page, sorting, filter } = this.state;
    const offset = perPage * (page - 1);

    const url = `/${this.props.resourcePath}/${this.props.parentId}/attachments`;
    const query = {
      page: {
        limit: perPage,
        offset: offset,
      },
      include: "user",
    };
    if (sorting) {
      query["sort"] = `${sorting.direction === DESC ? "-" : ""}${
        sorting.field
      }`;
    }
    if (filter) {
      query["filter"] = {
        query: filter,
        fields: "name,user.email",
      };
    }

    const requestSent = Date.now();
    this._lastLoadRequest = requestSent;

    const response = await API.get(url, { query });
    const data = await response.json();
    if (this._lastLoadRequest == requestSent) {
      this.setState({ data, isLoading: false });
    }
  }

  async onDelete(attachment) {
    const response = await API.del(`/attachments/${attachment.id}`);
    if (response.ok) {
      this.loadAttachments();
    }
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  onPerPageChange(perPage) {
    this.setState({ perPage, page: 1 }, this.debouncedLoadAttachments);
  }

  onPageChange(page) {
    this.setState({ page }, this.debouncedLoadAttachments);
  }

  onFilterChange(filter) {
    this.setState({ filter, page: 1 }, this.debouncedLoadAttachments);
  }

  onSortChange(field) {
    if (!this.state.sorting || this.state.sorting.field !== field) {
      this.setState(
        { sorting: { field, direction: ASC } },
        this.debouncedLoadAttachments
      );
      return;
    }

    this.setState(
      {
        sorting: {
          field,
          direction: this.state.sorting.direction === ASC ? DESC : ASC,
        },
      },
      this.debouncedLoadAttachments
    );
  }

  getAttachments() {
    if (!this.state.data || !this.state.data.data) {
      return [];
    }

    const findIncludedResource = (relation) => {
      if (!relation) {
        return null;
      }

      return this.state.data.included.find(
        (include) =>
          include.type === relation.type && include.id === relation.id
      );
    };
    const mapRelation = (relationships) => {
      if (!Array.isArray(relationships)) {
        return findIncludedResource(relationships);
      }

      return relationships
        .map(findIncludedResource)
        .filter((resource) => !!resource);
    };

    return this.state.data.data.map((item) => {
      const user = mapRelation(item.relationships.user.data) || {};
      return Object.assign({}, item.attributes, {
        id: item.id,
        user: user ? user.attributes : undefined,
      });
    });
  }

  render() {
    const { filter, page, perPage, data, sorting, isLoading } = this.state;
    const { simpleList, parentIsClone } = this.props;

    return (
      <AttachmentList
        simpleList={simpleList}
        parentIsClone={parentIsClone}
        attachments={this.getAttachments()}
        filter={filter}
        total={data ? data.meta.total_count : 0}
        perPage={perPage}
        page={page}
        sorting={sorting}
        isLoading={isLoading}
        onSortChange={this.onSortChange.bind(this)}
        onPerPageChange={this.onPerPageChange.bind(this)}
        onPageChange={this.onPageChange.bind(this)}
        onFilterChange={this.onFilterChange.bind(this)}
        onDelete={this.onDelete.bind(this)}
      />
    );
  }
}

export default AttachmentListContainer;

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
import "../initializers.js";
import "../tooltip.js";
import "../displayToggle.js";
import "../dataTables.js";
import FileExportForm from "../fileExportForm.js";
window.FileExportForm = FileExportForm;
import "../facility.js";
import "../confirmDeleteModal.js";
import "../maintenancePlanActionForm.js";
import "../message_dismissal.js";
import "../addActionToProjectModal.js";
import "../removeActionFromProjectModal.js";

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "@hotwired/turbo-rails";
Turbo.session.drive = false;

import "@stimulus/polyfills";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("stimulus_controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-clear-filters]").forEach((element) => {
    element.addEventListener("click", () => localStorage.clear());
  });
});

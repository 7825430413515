import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["enabled", "disabled"];

  enable() {
    this.enabledTarget.classList.remove("hidden");
    this.disabledTarget.classList.add("hidden");
  }

  disable() {
    this.enabledTarget.classList.add("hidden");
    this.disabledTarget.classList.remove("hidden");
  }
}

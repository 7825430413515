import { Controller } from "stimulus";
import debounce from "lodash.debounce";
import { qs } from "../lib/helpers";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  static values = { url: String };
  static targets = ["outputField", "inputField"];

  initialize() {
    this.onChange = debounce(this.onChange, 300).bind(this);
    this.observer = new MutationObserver(this.onChange);
  }

  connect() {
    this.inputFieldTargets.forEach((target) => {
      this.observer.observe(target, {
        attributeFilter: ["value"],
      });
      target.addEventListener("change", this.onChange);
    });
  }

  inputFieldTargetConnected(element) {
    this.observer.observe(element, {
      attributeFilter: ["value"],
    });
    element.addEventListener("change", this.onChange);
    this.onChange();
  }

  onChange() {
    let queryObject = {};
    for (const target of this.inputFieldTargets) {
      queryObject[target.name] = target.value;
    }

    const url = `${this.urlValue}?${qs(queryObject)}`;

    fetch(url)
      .then((res) => res.text())
      .then((html) => {
        PageLoader.updateDOM(html, "[data-total-price-target='outputField']");
      });
  }
}

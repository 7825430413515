import React, { useState } from "react";
import Dropzone from "react-dropzone";

import t from "../lib/translate";
import { classSet } from "../lib/helpers";

const AttachmentDropzone = (props) => {
  const [uploadState, setUploadState] = useState({ uploading: false });
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDrop = async (acceptedFiles, rejectedFiles) => {
    setIsDragOver(false);

    if (rejectedFiles.length > 0) {
      props.onNotice(getRejectedFileNotice(rejectedFiles, props.maxSize));
      return;
    }

    setUploadState({
      uploading: true,
      total: acceptedFiles.length,
      completed: 0,
    });
    props.onNotice(null);

    // Upload files sequentially to reduce load on backend
    let results = [];
    for (const file of acceptedFiles) {
      results.push(await props.onUpload(file));
      setUploadState((state) =>
        Object.assign({}, state, { completed: state.completed + 1 })
      );
    }
    setUploadState({ uploading: false });

    const successCount = results.filter((item) => item.success).length;
    props.onNotice(getUploadNotice(results));

    if (successCount > 0) {
      props.onUploadComplete();
    }
  };

  return (
    <React.Fragment>
      <Dropzone
        maxSize={props.maxSize}
        multiple={true}
        onDrop={handleDrop}
        onDragEnter={() => setIsDragOver(true)}
        onDragLeave={() => setIsDragOver(false)}
        disabled={uploadState.uploading}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: classSet("dropzone", {
                dragover: isDragOver,
              }),
              style: { marginBottom: "20px" },
            })}
          >
            <input {...getInputProps()} />
            <p>{getDropzoneText(uploadState, props.maxSize)}</p>
            {getAllowedExtensionsText(props.allowedExtensions)}
            <span>
              <i className="fa fa-plus" style={{ fontSize: "24px" }}></i>
            </span>
          </div>
        )}
      </Dropzone>
    </React.Fragment>
  );
};

const getUploadNotice = (results) => {
  const successCount = results.filter((item) => item.success).length;
  if (successCount == results.length) {
    return {
      alertType: "success",
      messages: [t("Uppladdningen är klar!")],
    };
  }

  let messages = [];
  let alertType = "danger";
  if (successCount > 0) {
    alertType = "info";
    messages.push(
      t("{:successful} av {:total} filer laddades upp.", {
        successful: successCount,
        total: results.length,
      })
    );
  }

  messages = messages.concat(
    results
      .filter((item) => !item.success)
      .map((item) => `${item.file}: ${item.errors}`)
  );

  return { alertType, messages };
};

const getRejectedFileNotice = (rejectedFiles, maxSize) => {
  for (const file of rejectedFiles) {
    if (file.size > maxSize) {
      return {
        alertType: "danger",
        messages: [
          `${t("Filer får inte vara större än")} ${bytesToMB(maxSize)} MB`,
        ],
      };
    }
  }

  return {
    alertType: "danger",
    messages: [t("Du försökte ladda upp en ogiltig fil.")],
  };
};

const getDropzoneText = (uploadState, maxSize) => {
  if (!uploadState.uploading) {
    return t("Dra filer hit eller klicka för att ladda upp (max {:max} MB)", {
      max: bytesToMB(maxSize),
    });
  }

  if (uploadState.total == 1) {
    return t("Laddar upp...");
  }

  return t("{:completed} av {:total} filer uppladdade...", uploadState);
};

const bytesToMB = (bytes) => Math.round(bytes / 1000 / 1000);

const getAllowedExtensionsText = (extensions) => {
  if (!extensions) {
    return;
  }

  return (
    <p>
      <small>{`${t("Tillåtna filtyper:")} ${extensions}`}</small>
    </p>
  );
};

export default AttachmentDropzone;

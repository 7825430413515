import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "subcheckbox"];

  connect() {
    this.updateSelection();
  }

  updateSelection(e) {
    const totalCount = this.subcheckboxTargets.length;
    const checkedCount = this.subcheckboxTargets.filter(
      (box) => box.checked
    ).length;

    if (totalCount === checkedCount) {
      this.checkboxTarget.checked = true;
      this.checkboxTarget.indeterminate = false;
    } else if (checkedCount === 0) {
      this.checkboxTarget.checked = false;
      this.checkboxTarget.indeterminate = false;
    } else {
      this.checkboxTarget.checked = false;
      this.checkboxTarget.indeterminate = true;
    }
  }

  toggleAll(e) {
    const changedElements = this.subcheckboxTargets.filter(
      (box) => box.checked !== e.target.checked
    );

    changedElements.forEach((box) => (box.checked = e.target.checked));
    const changeEvent = new Event("change", { bubbles: true });
    changedElements.forEach((box) => box.dispatchEvent(changeEvent));

    this.updateSelection();
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(this.element).tooltip();
  }

  disconnect() {
    $(this.element).tooltip("destroy");
  }
}

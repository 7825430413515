import morphdom from "morphdom";

const Action = { REPLACE: 0, PUSH: 1, POP: 2, IGNORE: 3 };

const load = (url, action) => {
  const loaderTimeout = window.setTimeout(() => {
    setLoaderDisplayValue("block");
  }, 300);

  fetch(url)
    .then((res) => {
      if (res.ok) {
        return res.text();
      }

      throw new Error(
        `Request returned an unexpected response code (${url.href}).`
      );
    })
    .then((html) => {
      window.clearTimeout(loaderTimeout);
      setLoaderDisplayValue("none");

      if (action !== Action.POP && action !== Action.IGNORE) {
        updateHistory(action, url.toString());
      }

      updateDOM(html, "#page-wrapper", action === Action.POP);
    })
    .catch((error) => {
      console.error(error);
      window.location.href = url.href;
    });
};

const updateDOM = (html, selector, force = false) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  morphdom(
    document.body.querySelector(selector),
    doc.body.querySelector(selector),
    {
      onBeforeElUpdated: (fromEl, toEl) => {
        if ("morphdomIgnore" in fromEl.dataset && !force) {
          return false;
        }

        if ("reactClass" in fromEl.dataset) {
          window.ReactRailsUJS.unmountComponents(fromEl.parentElement);
        }

        return true;
      },
    }
  );
  window.ReactRailsUJS.mountComponents(selector);
};

const updateHistory = (action, url) => {
  if (action === Action.REPLACE) {
    history.replaceState(null, "", url);
  } else if (action === Action.PUSH) {
    history.pushState(null, "", url);
  }
};

const setLoaderDisplayValue = (value) => {
  const loader = document.querySelector("#loader");
  if (loader) {
    loader.style.display = value;
  }
};

export default { Action, load, updateDOM };

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { fallback: String };
  static targets = ["box", "input"];

  connect() {
    this.setFallbackValue();
    this.updateVisibility();
  }

  setFallbackValue() {
    this.fallbackValue = this.inputTarget.value;
  }

  toggleInput() {
    if (!this.boxTarget.checked) {
      this.setFallbackValue();
      this.inputTarget.value = "";
      return;
    }

    if (!this.inputTarget.value) {
      this.inputTarget.value = this.fallbackValue;
    }
  }

  updateVisibility() {
    if (Boolean(this.fallbackValue) || Boolean(this.inputTarget.value)) {
      this.element.classList.remove("hidden");
      if (this.inputTarget.value) {
        this.boxTarget.checked = true;
        this.boxTarget.dispatchEvent(new Event("change"));
      }
    } else {
      this.element.classList.add("hidden");
    }
  }
}

const removeFromProject = () => {
  const modal = window.$("#modal-remove-project");
  if (!modal) {
    return;
  }

  modal.on("show.bs.modal", (e) => {
    const actionId = e.relatedTarget.dataset.actionId;

    document.querySelector(
      "#modal-remove-project input[type='hidden'][name='action_id']"
    ).value = actionId;
  });
};

window.addEventListener("DOMContentLoaded", removeFromProject);

import { Controller } from "stimulus";
import TomSelect from "tom-select";
import { defaultConfig } from "../config/tom-select";

export default class extends Controller {
  static values = {
    allowCreate: Boolean,
    preventDelete: Boolean,
    disableSearch: Boolean,
    allowClearOptions: Boolean,
  };

  connect() {
    this.customDropdown = new TomSelect(this.element, this.config());
  }

  disconnect() {
    this.customDropdown.destroy();
  }

  renderItem = (data, escape) => {
    if (!data.optgroup) {
      return "<div>" + escape(data.text) + "</div>";
    }

    let optgroup = null;
    if (data.$option.parentElement.tagName == "OPTGROUP") {
      optgroup = data.$option.parentElement;
    } else {
      optgroup = this.customDropdown.optgroups[data.optgroup];
    }
    return (
      "<div><strong>" +
      escape(optgroup?.label) +
      "</strong>: " +
      escape(data.text) +
      "</div>"
    );
  };

  config() {
    let config = Object.assign({}, defaultConfig, {
      create: this.allowCreateValue,
      maxItems: 1,
      onItemAdd: () => this.customDropdown.blur(),
      onDelete: () => {
        return !this.preventDeleteValue;
      },
      render: Object.assign({}, defaultConfig.render, {
        item: this.renderItem,
      }),
    });

    if (this.disableSearchValue) {
      config.controlInput = null;
    }

    if (this.allowCreateValue) {
      config.render.no_results = null;
    }

    this.addPlugins(config);

    return config;
  }

  disconnect() {
    // Since the HTML has been replaced, we need to overwrite
    // the contents of the revertSettings cache to we don't
    // end up with the wrong options.
    this.customDropdown.revertSettings = {
      innerHTML: this.element.innerHTML,
      tabIndex: this.element.tabIndex,
    };
    this.customDropdown.destroy();
  }

  addPlugins(config) {
    if (!this.hasAllowClearOptionsValue) {
      return;
    }

    if (this.allowClearOptionsValue == true) {
      config.plugins = {
        clear_button: {
          title: "Ta bort",
          html: (data) =>
            `<i class="fa fa-close clear-button" title="${data.title}"></i>`,
        },
      };
      return;
    }

    config.plugins = ["no_backspace_delete"];
  }
}

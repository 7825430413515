import React from "react";
import API from "../lib/API";

import t from "../lib/translate";

class MagicLinkGenerator extends React.Component {
  constructor() {
    super();
    this.state = {
      magicLink: "",
      error: "",
    };

    this.magicLinkField = React.createRef();

    this.copyToClipBoard = this.copyToClipBoard.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  get magicLinkExists() {
    return this.props.magic_link_exists || this.state.magicLink;
  }

  copyToClipBoard(event) {
    event.preventDefault();

    const field = this.magicLinkField.current;
    field.select();
    document.execCommand("copy");
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (!this.magicLinkExists || this.confirmOverwrite()) {
      const response = await API.post("magic_links");
      if (response.ok) {
        const data = await response.json();
        this.setState({ magicLink: data.data.magic_link });
      } else {
        this.setState({
          error: t("Något gick fel. Prova att ladda om sidan."),
        });
      }
    }
  }

  confirmOverwrite() {
    return window.confirm(
      "Är du säker? Det finns en gammal länk som kommer att bli ogiltig."
    );
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <Error>{this.state.error}</Error>
          <input
            className="form-control"
            name="magic_link"
            readOnly
            ref={this.magicLinkField}
            value={this.state.magicLink}
          />
        </div>
        <div className="form-group">
          <input
            className="btn btn-primary"
            style={{ marginRight: "3px" }}
            type="submit"
            value={this.magicLinkExists ? t("Generera om") : t("Generera")}
          />
          <button onClick={this.copyToClipBoard} className="btn btn-secondary">
            {t("Kopiera till urklipp")}
          </button>
        </div>
      </form>
    );
  }
}

const Error = ({ children }) => (
  <div
    style={{
      marginBottom: 5,
      marginTop: -10,
      marginLeft: 5,
      color: "#900",
    }}
  >
    {children}
  </div>
);

export default MagicLinkGenerator;

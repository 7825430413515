import React from "react";
import { bool, func, number, string } from "prop-types";

import PaginationControls from "./PaginationControls";
import PaginationInfo from "./PaginationInfo";

const PaginationRow = (props) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "32px",
    }}
  >
    <div>
      {!props.hideInfo && (
        <PaginationInfo
          page={props.page}
          itemsPerPage={props.itemsPerPage}
          totalNumberOfItems={props.totalNumberOfItems}
        />
      )}
    </div>
    <div>
      {!props.hideControls && (
        <PaginationControls
          page={props.page}
          perPage={props.itemsPerPage}
          total={props.totalNumberOfItems}
          onPageChange={props.setPage}
          ariaControls={props.ariaControls}
        />
      )}
    </div>
  </div>
);

PaginationRow.propTypes = {
  setPage: func.isRequired,
  page: number.isRequired,
  itemsPerPage: number.isRequired,
  totalNumberOfItems: number.isRequired,
  ariaControls: string.isRequired,
  hideControls: bool,
  hideInfo: bool,
};

export default PaginationRow;

import React from "react";
import PropTypes from "prop-types";

import Dropdown from "react-bootstrap/Dropdown";

import t from "../lib/translate";

const ActionsDropdown = ({ className, disabled, children }) => {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        variant="secondary"
        size="xs"
        aria-label={t("Fler alternativ")}
        disabled={disabled}
      >
        <i className="fa fa-ellipsis-h" aria-hidden="true" />
        <span className="sr-only">{t("Öppna meny")}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul" popperConfig={{ positionFixed: true }}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ActionsDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

const MenuItemLink = ({
  children,
  className,
  disabled = false,
  href = "#",
  onClick,
}) => {
  return (
    <Dropdown.Item as="li" disabled={disabled}>
      <a className={className} href={href} onClick={onClick}>
        {children}
      </a>
    </Dropdown.Item>
  );
};

MenuItemLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

const Divider = () => <Dropdown.Divider as="li" className="divider" />;

ActionsDropdown.MenuItemLink = MenuItemLink;
ActionsDropdown.Divider = Divider;

export default ActionsDropdown;

import TagListController from "./tag_list_controller";
import API from "../lib/API";

export default class extends TagListController {
  load = async (query, callback) => {
    const response = await API.get(`/facility_tags`);
    const result = await response.json();

    callback(
      result.data.map((item) => ({
        value: item.attributes.name,
        text: item.attributes.name,
      }))
    );
  };
}

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { default: Number };

  initialize() {
    this.previousValue = this.element.value;
  }

  convertMinValueToDefaultValueWhenUsingArrows(event) {
    if (!this.element.hasAttribute("min") || !this.hasDefaultValue) {
      return;
    }

    if (this.element.value === this.element.min && !this.previousValue) {
      this.element.value = this.defaultValue;
    }

    this.previousValue = this.element.value;
  }
}

import API from "./lib/API";

window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-message-id]").forEach((el) => {
    el.querySelector(".close-btn").addEventListener("click", () => {
      API.patch("/user_settings", {
        body: {
          key: `dismissed_${el.dataset.messageId}`,
          value: ":now",
        },
      });

      el.parentElement.removeChild(el);

      if (window.Intercom) {
        window.Intercom("trackEvent", `dismissed-${el.dataset.messageId}`);
      }
    });
  });
});

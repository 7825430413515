import { Controller } from "stimulus";
import TomSelect from "tom-select";
import { defaultConfigTags } from "../config/tom-select";

export default class extends Controller {
  static values = { preSelectedItems: Array };
  static targets = ["controlInput", "dropdownParent"];

  connect() {
    this.customDropdown = new TomSelect(
      this.element.querySelector("select"),
      this.config()
    );
  }

  config() {
    var config = {
      controlInput: this.controlInputTarget,
      dropdownParent: this.dropdownParentTarget,
    };

    if (this.preSelectedItemsValue.length > 0) {
      config.items = this.preSelectedItemsValue;
    }

    return Object.assign({}, defaultConfigTags, config);
  }

  disconnect() {
    this.customDropdown.destroy();
  }
}

import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

import { STATUS_OPTIONS } from "../lib/maintenance_plan_action";
import t from "../lib/translate";

const STATUSES = ["unchanged"].concat(STATUS_OPTIONS.map((item) => item.value));

const StatusPicker = ({ currentStatus, disabled, exclude = [], onSelect }) => {
  let renderStatuses = STATUSES.filter((status) => !exclude.includes(status));

  return (
    <div className="btn-group">
      <Dropdown style={{ display: "inline-block" }} className="status">
        <Dropdown.Toggle
          variant="secondary"
          size="sm"
          id="dropdown-basic"
          disabled={disabled}
          className={`form-control ${currentStatus}`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ display: "inline-block", flexGrow: 1 }}>
              {getStatusLabel(currentStatus)}
            </span>

            {!disabled && (
              <span
                className="caret"
                style={{ display: "inline-block", marginLeft: "3px" }}
              />
            )}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu as="ul" popperConfig={{ positionFixed: true }}>
          {renderStatuses.map((status) => (
            <StatusItem key={status} status={status} onSelect={onSelect} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const StatusItem = ({ onSelect, status }) => {
  return (
    <Dropdown.Item as="li">
      <a className={status} onClick={() => onSelect(status)}>
        {getStatusLabel(status)}
      </a>
    </Dropdown.Item>
  );
};

const getStatusLabel = (status) => {
  if (status == "unchanged") {
    return t("Oförändrad");
  }

  const item = STATUS_OPTIONS.find((option) => option.value == status);
  if (item) {
    return item.label;
  }

  return t("Okänd status");
};

export default StatusPicker;

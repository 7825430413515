import { Controller } from "stimulus";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  static targets = ["list"];

  submit(e) {
    e.preventDefault();
    const form = e.target.closest("form");

    fetch(form.action, {
      method: "POST",
      body: new FormData(form),
    }).then((response) => {
      if (response.ok) {
        this.reloadList();
        form.reset();
      }
    });
  }

  reloadList(e) {
    const showAll = e?.params?.showall;
    let url = new URL(window.location.href);

    if (showAll) {
      url.searchParams.set("show_all", true);
    }

    fetch(url)
      .then((res) => res.text())
      .then((html) => {
        history.pushState(null, "", url.href);
        PageLoader.updateDOM(html, `#${this.listTarget.id}`);
      });
  }
}

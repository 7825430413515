import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "./Modal";

import { CompleteActionSchema } from "../lib/schemas";
import t from "../lib/translate";
import { currency } from "../lib/helpers";

const CompleteActionModal = (props) => {
  const { action } = props;

  const handleSubmit = (values, actions) => {
    const data = {
      id: action.id,
      year: action.year,
      ...values,
    };

    props.completeAction(data, () => {
      actions.setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={{
        completed_year: "",
        final_cost: "",
        comment: "",
      }}
      validationSchema={CompleteActionSchema}
      onSubmit={handleSubmit}
      render={(formProps) => (
        <Form role="form">
          <Modal
            title={t("Genomför åtgärd")}
            buttons={
              <button
                type="submit"
                className="btn btn-primary"
                disabled={formProps.isSubmitting || !formProps.isValid}
              >
                {t("Genomför åtgärd")}
              </button>
            }
            onClose={props.hideModal}
            includeDialog={props.includeDialog}
          >
            <div className="alert alert-info">
              {t(
                "Ange information om den genomförda åtgärden. Detta kan vara väldigt värdefullt för framtida planering, så försök få med så mycket som möjligt!"
              )}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group required">
                  <label>{t("Genomfördes år")}</label>
                  <br />
                  <div className="input-group" style={{ width: 150 }}>
                    <span className="input-group-addon">{t("År")}</span>
                    <Field
                      type="number"
                      className="form-control"
                      name="completed_year"
                    />
                  </div>
                  <ErrorMessage
                    component={ErrorDisplay}
                    name="completed_year"
                  />

                  <p className="help-block">
                    {t("Nuvarande år:")}{" "}
                    <SuggestedValueLink
                      formProps={formProps}
                      name="completed_year"
                      value={new Date().getFullYear()}
                    />
                    {action.year !== new Date().getFullYear() &&
                      action.year <= new Date().getFullYear() + 1 && (
                        <>
                          <br />
                          {t("Planerat år:")}{" "}
                          <SuggestedValueLink
                            formProps={formProps}
                            name="completed_year"
                            value={action.year}
                          />
                        </>
                      )}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t("Faktiskt pris (exkl. moms)")}</label>
                  <br />
                  <div className="input-group" style={{ width: 200 }}>
                    <Field
                      className="form-control"
                      name="final_cost"
                      type="number"
                    />
                    <span className="input-group-addon">{t("kr")}</span>
                  </div>
                  <ErrorMessage component={ErrorDisplay} name="final_cost" />
                  <p className="help-block">
                    {t("Planerat pris:")}{" "}
                    <SuggestedValueLink
                      formProps={formProps}
                      name="final_cost"
                      value={Math.round(action.total_price)}
                      label={currency(action.total_price)}
                    />{" "}
                    {t("exkl. moms.")}
                  </p>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>{t("Kommentar")}</label>
              <br />
              <Field
                name="comment"
                component="textarea"
                className="form-control"
              />
              <ErrorMessage component={ErrorDisplay} name="comment" />
              <p className="help-block">
                {t(
                  "Exempelvis vem som utförde åtgärden, vilka produkter/fabrikat som användes eller annan information som inte framgår av åtgärden."
                )}
              </p>
            </div>
          </Modal>
        </Form>
      )}
    />
  );
};

const SuggestedValueLink = ({ formProps, name, value, label = undefined }) => (
  <a
    href="#"
    onClick={(event) => {
      event.preventDefault();
      formProps.setFieldValue(name, value);
    }}
  >
    {label || value}
  </a>
);

const ErrorDisplay = (props) => (
  <div style={{ marginTop: "5px", marginLeft: "5px", color: "#900" }}>
    {props.children}
  </div>
);

export default CompleteActionModal;

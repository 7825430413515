import React from "react";
import PropTypes from "prop-types";

import PaginationRow from "./PaginationRow";
import LoadWrapper from "./LoadWrapper";
import ListSettings from "./ListSettings";
import ListHeaderCell from "./ListHeaderCell";

import t from "../lib/translate";
import { currency } from "../lib/helpers";

const SuggestionList = (props) => {
  return (
    <React.Fragment>
      <ListSettings
        filter={props.filter}
        onFilterChange={(event) => props.onFilterChange(event.target.value)}
        onPerPageChange={(event) =>
          props.onPerPageChange(parseInt(event.target.value, 10))
        }
        perPage={props.perPage}
        ariaControls="suggestion-list"
      />

      <LoadWrapper isLoading={props.isLoading} key={props.isLoading ? 1 : 0}>
        <table
          className="table table-hover table-suggestions pl-data-table striped"
          id="suggestion-list"
        >
          <thead>
            <tr>
              <th style={{ width: "40px" }}></th>
              {[
                { field: "name", title: t("Namn"), width: 200 },
                {
                  field: "unit_price",
                  title: t("Styckpris"),
                  className: "right-align",
                },
                { field: "unit", title: t("Enhet") },
                { field: "repeated", title: t("Intervall") },
              ].map((headerItem) => (
                <ListHeaderCell
                  key={headerItem.field}
                  {...headerItem}
                  sorting={props.sorting}
                  onSortChange={props.onSortChange}
                />
              ))}
            </tr>
          </thead>

          <tbody>
            {props.items.map((suggestion) => (
              <tr
                onClick={() => props.onSelect(suggestion)}
                onKeyPress={(e) =>
                  e.key == "Enter" && props.onSelect(suggestion)
                }
                className="clickable"
                key={suggestion.id}
                tabIndex="0"
              >
                <td>{suggestion.attributes.built_in && <PlanimaLogo />}</td>
                <td>{suggestion.attributes.name}</td>
                <td className="right-align">
                  {currency(suggestion.attributes.unit_price)}
                </td>
                <td>{suggestion.attributes.unit}</td>
                <td>
                  {suggestion.attributes.repeated &&
                    `${suggestion.attributes.repeated} ${t("år")}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </LoadWrapper>

      <PaginationRow
        page={props.page}
        itemsPerPage={props.perPage}
        totalNumberOfItems={props.total}
        setPage={props.onPageChange}
        ariaControls="suggestion-list"
      />

      <div className="help-block well mb-0">
        <h5 className="mt-0">Saknar du någonting?</h5>
        <p>
          Listan inkluderar förslag från <strong>Planima-priser™</strong>, en
          smart prislista för underhåll. Om du tycker att någonting saknas i
          Planima-priser™ så får du gärna{" "}
          <a
            href="https://share.hsforms.com/1sTVJ1zgwRRGjWqC_Aieyvg4asjk"
            target="_blank"
            rel="noopener noreferrer"
          >
            skicka in ett önskemål genom vårt formulär
          </a>
          !
        </p>
        <p>
          För dig med mer avancerade behov går det även att själv skapa och
          redigera åtgärdsförslag -{" "}
          <a href="javascript:Intercom('show')">
            kontakta vår support för mer information
          </a>
          !
        </p>
      </div>
    </React.Fragment>
  );
};

const PlanimaLogo = () => (
  <div
    className="logo-element-bw"
    alt="Logga för Planima-priser"
    title={"Planima-pris"}
    data-cy="planima-logo"
  ></div>
);

SuggestionList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      unit: PropTypes.string,
      unit_price: PropTypes.number,
      repeated: PropTypes.number,
      built_in: PropTypes.bool,
    })
  ).isRequired,
  filter: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  onSortChange: PropTypes.func,
  onPerPageChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onSelect: PropTypes.func,
};

export default SuggestionList;

import { useState, useEffect } from "react";
import API from "../lib/API";

const useAPI = (path, opts = {}) => {
  const method = opts.method || "get";

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const response = await API.request(method, path, opts);
        if (response.ok) {
          setData(await response.json());
        } else {
          setError(response.statusText);
        }
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [path, JSON.stringify(opts)]);

  return {
    data,
    error,
    isLoading,
  };
};

export default useAPI;

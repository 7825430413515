import React from "react";
import SuggestionList from "./SuggestionList";
import ListContainer from "./ListContainer";

const SuggestionListContainer = (props) => {
  return (
    <ListContainer
      {...props}
      endpoint={"/maintenance_plan_action_suggestions"}
      baseQuery={{}}
      shouldLoadData={() => true}
      component={SuggestionList}
      getItemsFromData={(data) => data}
    />
  );
};

export default SuggestionListContainer;

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link", "button"];

  update({ target, params: { id } }) {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const allSelected = urlParams.get("all") === "true";
    const includeInParams = allSelected != target.checked;

    this.updateLinkUrls(id, includeInParams);
    this.updateBrowserUrl(urlParams, id, includeInParams);

    if (url.searchParams.has("ids[]") || allSelected) {
      this.enableButtons();
    } else {
      this.disableButtons();
    }

    history.replaceState(null, "", url);
  }

  updateLinkUrls(id, includeInParams) {
    this.linkTargets.forEach((link) => {
      const linkUrl = new URL(link.href);
      const linkParams = linkUrl.searchParams;

      includeInParams
        ? this.addID(linkParams, id)
        : this.removeID(linkParams, id);

      link.href = linkUrl.href;
    });
  }

  updateBrowserUrl(params, id, includeInParams) {
    includeInParams ? this.addID(params, id) : this.removeID(params, id);
  }

  addID(params, id) {
    params.append("ids[]", id);
  }

  removeID(params, id) {
    params.delete("ids[]", id);
  }

  enableButtons() {
    this.buttonTargets.forEach((button) => button.removeAttribute("disabled"));
  }

  disableButtons() {
    this.buttonTargets.forEach((button) =>
      button.setAttribute("disabled", true)
    );
  }
}

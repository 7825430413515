import React from "react";
import PropTypes from "prop-types";
import throttle from "lodash.throttle";

const SPINNER_FONT_SIZE = 36;

class LoadWrapper extends React.Component {
  constructor() {
    super();
    this.state = {};
    this._overlay = React.createRef();

    this.debouncedCalculateSpinnerPositon = throttle(
      this.calculateSpinnerPositon.bind(this),
      10
    );
  }

  componentDidMount() {
    window.addEventListener("scroll", this.debouncedCalculateSpinnerPositon);
    this.calculateSpinnerPositon();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.debouncedCalculateSpinnerPositon);
  }

  componentDidUpdate() {
    this.debouncedCalculateSpinnerPositon();
  }

  calculateSpinnerPositon() {
    if (!this._overlay.current) {
      return;
    }

    const rect = this._overlay.current.getBoundingClientRect();
    const containerTop = Math.max(0, 0 - rect.top);
    const containerBottom = Math.min(
      rect.bottom - rect.top,
      window.innerHeight - rect.top
    );

    this.setState({
      top: Math.round((containerTop + containerBottom - SPINNER_FONT_SIZE) / 2),
    });
  }

  render() {
    const { isLoading, minHeight = 150, children } = this.props;

    return (
      <div
        className="modal-contentwrapper"
        style={{
          position: "relative",
          minHeight: isLoading ? minHeight : undefined,
        }}
      >
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "rgba(255,255,255,0.5)",
              zIndex: 1,
            }}
            ref={this._overlay}
          >
            <i
              className="fa fa-spinner fa-spin fa-spin-big"
              style={{
                display: "block",
                position: "absolute",
                left: "50%",
                top: this.state.top || "50%",
                fontSize: SPINNER_FONT_SIZE,
              }}
            />
          </div>
        ) : null}
        {children}
      </div>
    );
  }
}

LoadWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
};

export default LoadWrapper;

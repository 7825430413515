const copyPlan = () => {
  const modal = window.$("#modal-copy");
  if (!modal) {
    return;
  }

  modal.on("show.bs.modal", (e) => {
    const planId = e.relatedTarget.dataset.planId;
    const planName = e.relatedTarget.dataset.planName;

    document.querySelector(
      "#modal-copy input[type='hidden'][name='plan_id']"
    ).value = planId;
    document.querySelector("#modal-copy input[name='name']").value = planName;
  });
};

window.addEventListener("DOMContentLoaded", copyPlan);

import { Controller } from "stimulus";
import TomSelect from "tom-select";
import { defaultConfigTags } from "../config/tom-select";
import API from "../lib/API";

export default class extends Controller {
  static targets = ["controlInput", "dropdownParent", "resetTagsValue"];
  static values = { otherTags: String, otherTagsCount: Number };

  connect() {
    this.customDropdown = new TomSelect(
      this.element.querySelector("select"),
      this.config()
    );
  }

  config() {
    return Object.assign({}, defaultConfigTags, {
      controlInput: this.controlInputTarget,
      dropdownParent: this.dropdownParentTarget,
      create: true,
      onInitialize: this.onInitialize,
      load: this.load,
    });
  }

  onInitialize = () => {
    // Add button for removing other tags if any
    if (this.hasOtherTagsCountValue && Boolean(this.otherTagsCountValue)) {
      const text =
        document.querySelector(".chip-container").children.length > 0
          ? ` + ${this.otherTagsCountValue} olika`
          : `${this.otherTagsCountValue} olika`;

      document.querySelector(".chip-container").insertAdjacentHTML(
        "beforeend",
        `<span data-html="true" class="chip item multiple" data-value data-ts-item data-toggle="tooltip" title="${this.otherTagsValue}">
          ${text}
          <a href="javascript:void(0)" class="remove" tabindex="-1" data-action="tag-list#resetTags" title="Ta bort">
            <i class="fa fa-close"></i>
          </a>
        </span>`
      );
    }

    // Fix styling of remove_button plugin
    document.querySelectorAll(".plugin-remove_button").forEach((item) => {
      item.classList.remove("plugin-remove_button");
    });

    // Attach listener to maintenance plan selector
    const planSelect = document.querySelector(
      "#maintenance_plan_action_maintenance_plan_id"
    );
    if (planSelect) {
      planSelect.addEventListener("change", () => {
        this.customDropdown.clearOptions();
        this.customDropdown.load("");
      });
    }
  };

  load = async (query, callback) => {
    const planID = document.querySelector(
      "#maintenance_plan_action_maintenance_plan_id"
    )?.value;

    let url = "/maintenance_plan_action_tags";
    if (planID) {
      url += `?maintenance_plan=${planID}`;
    }

    const response = await API.get(url);
    const result = await response.json();

    callback(
      result.data.map((item) => ({
        value: item.attributes.name,
        text: item.attributes.name,
      }))
    );
  };

  disconnect() {
    this.customDropdown.destroy();
  }

  resetTags(e) {
    e.stopPropagation();
    const hiddenField = this.resetTagsValueTarget;
    const chip = e.currentTarget.parentElement;
    const tooltip = chip.nextSibling;

    hiddenField.value = true;
    chip.remove();
    tooltip.remove();
  }
}

window.addEventListener("DOMContentLoaded", () => {
  // Toggle target visibility on element click or change in select element
  document.querySelectorAll('[data-toggle="display"]').forEach((element) => {
    if (element?.type?.toLowerCase() === "select-one") {
      const updateVisibility = (select) => {
        select.options.forEach((option) => {
          if (option.selected) {
            return;
          }
          const targets = document.querySelectorAll(option.dataset.target);
          targets.forEach((target) => target.classList.add("hidden"));
        });
        select.options.forEach((option) => {
          if (!option.selected) {
            return;
          }
          const targets = document.querySelectorAll(option.dataset.target);
          targets.forEach((target) => target.classList.remove("hidden"));
        });
      };
      element.addEventListener("change", (event) => {
        updateVisibility(event.target);
      });
      updateVisibility(element);
    } else {
      element.addEventListener("click", () => {
        const target = document.querySelector(element.dataset.target);
        const klass = element.dataset.cssClass || "hidden";
        target.classList.toggle(klass);
      });
    }
  });

  // Tabs
  document.querySelectorAll('[data-toggle="tabs"]').forEach((element) => {
    const target = document.querySelector(`#${element.dataset.targetId}`);
    const tabs = document.querySelectorAll(element.dataset.tabSelector);
    const activateTab = () => {
      // Display tab content
      tabs.forEach((tab) => {
        tab.classList.add("hidden");
      });
      target.classList.remove("hidden");

      // Mark tab as active
      document
        .querySelectorAll(
          `[data-toggle="tabs"][data-tab-selector="${element.dataset.tabSelector}"]`
        )
        .forEach((btn) => btn.classList.remove("active"));
      element.classList.add("active");
    };

    element.addEventListener("click", () => {
      activateTab(target, tabs);
      history.pushState(null, null, `#${element.dataset.targetId}`);
    });

    // On page load, activate tab immediately if hash matches target ID
    if (document.location.hash.replace("#", "") === element.dataset.targetId) {
      activateTab();
    }
  });

  // Text
  document.querySelectorAll('[data-toggle="text"]').forEach((element) => {
    const target = document.querySelector(`#${element.dataset.toggleTargetId}`);
    const originalText = target.innerHTML;
    const toggleText = element.dataset.toggleToText;

    const toggleInnerText = () => {
      if (target.innerHTML == originalText) {
        target.innerHTML = toggleText;
      } else {
        target.innerHTML = originalText;
      }
    };

    element.addEventListener("click", () => {
      toggleInnerText();
    });
  });
});

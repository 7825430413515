import * as Yup from "yup";

const currentYear = new Date().getFullYear();

const CompleteActionSchema = Yup.object().shape({
  completed_year: Yup.number()
    .integer()
    .min(1900)
    .max(currentYear + 1)
    .required(),
  final_cost: Yup.number().min(0),
  comment: Yup.string(),
});

export { CompleteActionSchema };

import React from "react";
import PieChart from "./PieChart";
import { array, arrayOf, number, string } from "prop-types";
import t from "../lib/translate";

const AnalysisPieChart = ({ cols = 4, series, name, colors }) => (
  <div className={`col-md-${cols}`}>
    <div className="ibox ibox-kpi">
      <div className="ibox-title">
        <h5>{t(`Utgift per ${name}`)}</h5>
      </div>
      <div className="ibox-content">
        <div className="analysis-pie-chart">
          <PieChart series={series} colors={colors} />
        </div>
      </div>
    </div>
  </div>
);

export default AnalysisPieChart;

AnalysisPieChart.prototypes = {
  cols: number,
  series: arrayOf(array).isRequired,
  title: string,
  tooltip: string,
};

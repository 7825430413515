import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["outputField", "inputField"];

  connect() {
    this.inputFieldTargets.forEach((target) =>
      target.addEventListener("change", this.onTouch)
    );
  }

  inputFieldTargetConnected(element) {
    element.addEventListener("change", this.onTouch);
  }

  inputFieldTargetDisconnected(element) {
    element.removeEventListener("change", this.onTouch);
  }

  onTouch = () => {
    this.outputFieldTarget.value = true;
  };
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "visibleIcon", "hiddenIcon"];

  connect() {
    this.hidden = this.inputTarget.type === "password";
    this.updateVisibility();
  }

  toggle(e) {
    e.preventDefault();

    this.hidden = !this.hidden;
    this.updateVisibility();
  }

  updateVisibility() {
    this.inputTarget.type = this.hidden ? "password" : "text";

    if (this.hidden) {
      this.visibleIconTarget.classList.remove("hidden");
      this.hiddenIconTarget.classList.add("hidden");
    } else {
      this.visibleIconTarget.classList.add("hidden");
      this.hiddenIconTarget.classList.remove("hidden");
    }
  }
}

import AutocompleteController from "./autocomplete_controller";
import React from "react";
import ReactDOM from "react-dom";

import Modal from "../lib/modal";
import ActionSuggestionModal from "../components/ActionSuggestionModal";

export default class extends AutocompleteController {
  static targets = ["input", "results", "info", "infoText"];
  static values = {
    formScope: { type: String, default: "" },
  };

  initialize() {
    super.initialize();
    this.modal = new Modal();
  }

  connect() {
    super.connect();
    this.inputTarget.addEventListener("click", this.showResults.bind(this));
  }

  onKeyDown(e) {
    if (
      this.resultsTarget.classList.contains("hidden") &&
      !this.modal.isVisible
    ) {
      return;
    }

    super.onKeyDown(e);
  }

  showModal = (e) => {
    const includeFilter = e?.params?.useQuery;
    ReactDOM.render(
      this.getReactComponent(includeFilter),
      this.getReactRootNode()
    );
    this.modal.show();
  };

  hideModal = (resetFocus = true) => {
    if (!this.modal.isVisible) {
      return;
    }

    this.modal.hide();
    ReactDOM.unmountComponentAtNode(this.getReactRootNode());

    if (resetFocus) {
      this.inputTarget.focus();
    }
  };

  getReactRootNode() {
    return this.modal.getModalElement().querySelector(".modal-content");
  }

  async search() {
    if (this.inputTarget !== document.activeElement) {
      return;
    }

    super.search("/actions/autocomplete", this.inputTarget.value);
  }

  onSelect = (item) => {
    this.hideModal(false);
    this.hideResults();

    const form = document.querySelector(this.formScopeValue);
    const fieldValues = Object.assign({}, item, {
      maintenance_plan_action_suggestion_id: item.id,
      indexation_starts_on_year: new Date().getFullYear(),
    });

    form.querySelector("#connected_suggestion_name").innerText =
      `”${fieldValues.name}”`;

    [
      "name",
      "unit",
      "unit_price",
      "repeated",
      "maintenance_plan_action_suggestion_id",
      "indexation_starts_on_year",
    ].forEach(function (key) {
      const element = form.querySelector(
        `[name="maintenance_plan_action[${key}]"]`
      );
      element.value = fieldValues[key];
      element.dispatchEvent(new Event("change"));
    });

    const text = fieldValues["info"] || "";
    this.showInfoText(text !== "");
    this.updateInfoText(text);
  };

  showInfoText(visible) {
    this.infoTarget.hidden = !visible;
  }

  updateInfoText(text) {
    this.infoTextTarget.innerHTML = "";
    text
      .split(/(\r\n|\r){2,}/)
      .map((paragraph) => paragraph.trim())
      .filter((paragraph) => paragraph !== "")
      .forEach((paragraph) => {
        const pElement = document.createElement("p");
        paragraph.split(/\r\n|\r/).forEach((line, index, array) => {
          pElement.appendChild(document.createTextNode(line));
          if (index < array.length - 1) {
            pElement.appendChild(document.createElement("br"));
          }
        });
        this.infoTextTarget.appendChild(pElement);
      });
  }

  getReactComponent = (includeFilter) => {
    return React.createElement(ActionSuggestionModal, {
      onSelect: (item) => {
        this.onSelect(
          Object.assign(
            {
              id: item.id,
            },
            item.attributes
          )
        );
      },
      onClose: this.hideModal,
      initialFilter: includeFilter ? this.inputTarget.value : null,
    });
  };
}

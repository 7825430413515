import React, { useEffect, useRef, useState } from "react";

import MaintenancePlanSelect from "./MaintenancePlanSelect";

import PageLoader from "../lib/page_loader";

const RailsMaintenancePlanSelect = ({ initialValue, formElementName }) => {
  const [values, setValues] = useState(initialValue);
  useURLAndPageLoadEffect(formElementName, values);

  return (
    <>
      <MaintenancePlanSelect value={values} onChange={setValues} />

      {values.map((value) => (
        <input type="hidden" name={formElementName} value={value} key={value} />
      ))}
    </>
  );
};

const useURLAndPageLoadEffect = (formElementName, values) => {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    searchParams.delete(formElementName);
    for (const value of values) {
      searchParams.append(formElementName, value);
    }
    url.search = searchParams;

    PageLoader.load(url, PageLoader.Action.PUSH);
  }, [formElementName, values]);
};

export default RailsMaintenancePlanSelect;

import React from "react";
import PropTypes from "prop-types";

import PaginationRow from "./PaginationRow";
import LoadWrapper from "./LoadWrapper";
import ListSettings from "./ListSettings";
import ListHeaderCell from "./ListHeaderCell";

import t from "../lib/translate";
import ActionsDropdown from "./ActionsDropdown";

const fileSizeMB = (attachment) =>
  `${Math.round((100 * attachment.size) / 1000000) / 100} MB`;
const localeDate = (date) => new Date(date).toLocaleDateString("sv-SE");

const AttachmentList = (props) => {
  const handleDelete = (attachment) => {
    const shouldDelete = window.confirm(
      "Är du säker på att du vill ta bort filen?"
    );
    if (shouldDelete) {
      props.onDelete(attachment);
    }
  };

  if (props.simpleList) {
    return <SimpleAttachmentList {...props} onDelete={handleDelete} />;
  }

  return (
    <>
      <ListSettings
        filter={props.filter}
        onFilterChange={(event) => props.onFilterChange(event.target.value)}
        onPerPageChange={(event) =>
          props.onPerPageChange(parseInt(event.target.value, 10))
        }
        perPage={props.perPage}
        ariaControls="attachment-list"
      />

      <LoadWrapper isLoading={props.isLoading} key={props.isLoading ? 1 : 0}>
        <table className="custom-table" id="attachment-list">
          <thead>
            <tr>
              <th className="thumb" />
              {[
                { field: "name", title: t("Namn"), width: 200 },
                { field: "size", title: t("Filstorlek") },
                { field: "user.email", title: t("Uppladdat av") },
              ].map((headerItem) => (
                <ListHeaderCell
                  key={headerItem.field}
                  {...headerItem}
                  sorting={props.sorting}
                  onSortChange={props.onSortChange}
                />
              ))}
              <th />
            </tr>
          </thead>

          <tbody>
            {props.attachments.map((attachment) => (
              <tr key={attachment.id}>
                <td className="text-center img" width="75">
                  <ThumbnailLink attachment={attachment} />
                </td>
                <td>
                  <FileLink url={attachment.url}>{attachment.name}</FileLink>
                </td>
                <td>{fileSizeMB(attachment)}</td>
                <td>
                  {attachment.user
                    ? attachment.user.email
                    : t("Borttagen användare")}
                </td>
                <td className="text-right destructive">
                  <Menu attachment={attachment} onDelete={handleDelete} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </LoadWrapper>

      <PaginationRow
        page={props.page}
        itemsPerPage={props.perPage}
        totalNumberOfItems={props.total}
        setPage={props.onPageChange}
        ariaControls="attachment-list"
      />
    </>
  );
};

const SimpleAttachmentList = (props) => {
  if (props.attachments.length === 0) {
    return null;
  }

  return (
    <LoadWrapper isLoading={props.isLoading} key={props.isLoading ? 1 : 0}>
      <table className="custom-table" id="attachment-list">
        <tbody>
          {props.attachments.map((attachment) => (
            <tr key={attachment.id}>
              <td className="text-center img" width="100">
                <ThumbnailLink attachment={attachment} />
              </td>
              <td>
                <div className="file-name">
                  <FileLink url={attachment.url}>{attachment.name}</FileLink>
                  <br />
                  <small>
                    {fileSizeMB(attachment)} •{" "}
                    {localeDate(attachment.created_at)} •{" "}
                    {attachment.user
                      ? attachment.user.email
                      : t("Borttagen användare")}
                  </small>
                </div>
              </td>
              <td className="text-right destructive">
                <Menu attachment={attachment} onDelete={props.onDelete} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {props.total > props.perPage && (
        <PaginationRow
          page={props.page}
          itemsPerPage={props.perPage}
          totalNumberOfItems={props.total}
          setPage={props.onPageChange}
          ariaControls="attachment-list"
        />
      )}
    </LoadWrapper>
  );
};

const Menu = ({ attachment, onDelete }) => {
  if (!attachment.can_destroy) {
    return null;
  }

  return (
    <ActionsDropdown>
      <ActionsDropdown.MenuItemLink
        onClick={() => onDelete(attachment)}
        className="delete destructive"
      >
        <i className="fa fa-trash-can"></i> {t("Ta bort")}
      </ActionsDropdown.MenuItemLink>
    </ActionsDropdown>
  );
};

const ThumbnailLink = ({ attachment }) => (
  <FileLink url={attachment.url}>
    {["jpg", "jpeg", "png", "gif"].includes(attachment.extension) ? (
      <img src={attachment.url} className="img-thumbnail" />
    ) : (
      <i className={`fa big ${attachment.extension}`} />
    )}
  </FileLink>
);

const FileLink = ({ url, children }) => (
  <a
    href={url}
    className="skip-external-arrow"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

AttachmentList.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
      can_destroy: PropTypes.bool.isRequired,
      user: PropTypes.shape({
        email: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  filter: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  sorting: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onPerPageChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AttachmentList;

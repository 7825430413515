import React from "react";

import t from "../lib/translate";

const ListItemsPerPageSelection = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <label style={{ whiteSpace: "nowrap", margin: 0, marginRight: 16 }}>
        {t("Antal rader")}:
      </label>
      <select
        className="form-control"
        aria-controls={props.ariaControls}
        onChange={props.onChange}
        value={props.value}
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
  );
};

export default ListItemsPerPageSelection;

const debounceAsyncFunctionWithCallback = (asyncFunction, time) => {
  let lastCall = 0;
  let timeout;

  return (input, callback) => {
    const call = Date.now();
    lastCall = call;
    clearTimeout(timeout);

    timeout = setTimeout(async () => {
      const result = await asyncFunction(input);
      if (lastCall === call) {
        callback(result);
      }
    }, time);
  };
};

export default debounceAsyncFunctionWithCallback;

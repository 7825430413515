import { Controller } from "stimulus";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  update(event) {
    event.preventDefault();
    this.disconnectSelectControllers();
    PageLoader.load(this.element.href, PageLoader.Action.PUSH);
  }

  disconnectSelectControllers() {
    const elements = document.querySelectorAll("[data-controller*='select']");

    elements.forEach((element) => {
      element.dataset.controller = element.dataset.controller
        .split(" ")
        .filter((controller) => {
          return controller !== "select";
        })
        .join(" ");
    });
  }
}

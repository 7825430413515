import PropTypes from "prop-types";

const { arrayOf, func, number, oneOfType, shape, string } = PropTypes;

export const paginationType = shape({
  setPage: func.isRequired,
  page: number.isRequired,
  itemsPerPage: number.isRequired,
  totalNumberOfItems: number.isRequired,
});

export const filterType = shape({
  actionType: string,
  building: arrayOf(oneOfType([string, number])),
  category_name: arrayOf(oneOfType([string, number])),
  endYear: number,
  location: arrayOf(oneOfType([string, number])),
  startYear: number,
  status: arrayOf(string),
  fromTotalPrice: oneOfType([number, string]),
  toTotalPrice: oneOfType([number, string]),
  planSelectionList: arrayOf(string),
});

import React from "react";
import PropTypes from "prop-types";

const ASC = 0;
const DESC = 1;

const ListHeaderCell = ({
  onSortChange,
  sorting,
  field,
  title,
  ...otherProps
}) => {
  let sortArrow = <i className="fa fa-fw fa-sort" />;
  if (sorting && sorting.field == field) {
    const direction = sorting.direction === ASC ? "asc" : "desc";
    sortArrow = <i className={`fa fa-fw fa-sort-${direction}`} />;
  }
  return (
    <th
      style={{ cursor: "pointer", whiteSpace: "nowrap" }}
      onClick={() => onSortChange(field)}
      {...otherProps}
    >
      {title}
      {sortArrow}
    </th>
  );
};

ListHeaderCell.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  sorting: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.number,
  }).isRequired,
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ListHeaderCell;

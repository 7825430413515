import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "investment",
    "maintenance",
    "inputs",
    "hiddenField",
    "investmentRadio",
    "maintenanceRadio",
  ];

  show(event) {
    event.preventDefault();

    this.populateInputs();
    this.inputsTarget.classList.toggle("hidden");
  }

  populateInputsFromCheckboxChoice() {
    this.populateInputs();
    this.updateHiddenField();
  }

  update(event) {
    this.updateCorrespondingInput(event.target);
    this.toggleRadioButtons();
    this.updateHiddenField();
  }

  updateCorrespondingInput(target) {
    this.capTargetValue(target);

    if (target == this.investmentTarget) {
      this.maintenanceTarget.value = 100 - this.investmentTarget.value;
    } else if (target == this.maintenanceTarget) {
      this.investmentTarget.value = 100 - this.maintenanceTarget.value;
    }
  }

  capTargetValue(target) {
    if (target.value > 100) {
      target.value = 100;
    } else if (target.value < 0 || target.value == "" || isNaN(target.value)) {
      target.value = 0;
    }
  }

  toggleRadioButtons() {
    this.investmentRadioTarget.checked = this.investmentTarget.value == 100;
    this.maintenanceRadioTarget.checked = this.maintenanceTarget.value == 100;
  }

  populateInputs() {
    if (
      !this.investmentRadioTarget.checked &&
      !this.maintenanceRadioTarget.checked
    ) {
      return;
    }

    if (this.investmentRadioTarget.checked) {
      this.investmentTarget.value = 100;
      this.maintenanceTarget.value = 0;
    } else {
      this.investmentTarget.value = 0;
      this.maintenanceTarget.value = 100;
    }
  }

  updateHiddenField() {
    this.hiddenFieldTarget.value = this.investmentTarget.value / 100;
  }
}

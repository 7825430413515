import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["resellerView", "planImports", "granularPermissions"];

  connect() {
    this.disableReadOnlyCheckbox();
  }

  updateCheckboxStates(event) {
    if (event.target == this.resellerViewTarget) {
      this.granularPermissionsTarget.disabled = this.resellerViewTarget.checked;
      this.granularPermissionsTarget.checked = false;
      this.planImportsTarget.disabled = this.resellerViewTarget.checked;
      this.planImportsTarget.checked = true;
    }

    if (event.target == this.granularPermissionsTarget) {
      this.resellerViewTarget.disabled = this.granularPermissionsTarget.checked;
      this.resellerViewTarget.checked = false;
    }
  }

  disableReadOnlyCheckbox() {
    this.granularPermissionsTarget.disabled = this.resellerViewTarget.checked;
    this.resellerViewTarget.disabled = this.granularPermissionsTarget.checked;
    this.planImportsTarget.disabled = this.resellerViewTarget.checked;
    this.planImportsTarget.checked =
      this.resellerViewTarget.checked || this.planImportsTarget.checked;
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text"];

  copy() {
    const input = document.createElement("input");
    document.querySelector("body").appendChild(input);
    input.value = this.textTarget.innerText;
    input.select();
    document.execCommand("copy");
    document.querySelector("body").removeChild(input);
  }
}

import React from "react";
import { Pie } from "react-chartjs-2";
import {
  CHART_COLORS,
  legendSettings,
  eventSettings,
  tooltipColorSettings,
} from "../lib/chartHelpers";
import { currency } from "../lib/helpers";
import { shape, arrayOf, string, number } from "prop-types";

const PieChart = ({ series, colors = CHART_COLORS }) => {
  return (
    <Pie
      data={{
        labels: series.map((item) => item[0]),
        datasets: [
          {
            data: series.map((item) => item[1]),
            backgroundColor: colors,
          },
        ],
      }}
      options={options}
    />
  );
};

const options = {
  maintainAspectRatio: false,
  legend: legendSettings,
  ...eventSettings,
  tooltips: {
    position: "nearest",
    callbacks: {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        return [
          data.labels[tooltipItem.index],
          currency(dataset.data[tooltipItem.index], true),
        ];
      },
      footer: function (tooltipItems, data) {
        const dataset = data.datasets[tooltipItems[0].datasetIndex];
        const value = dataset.data[tooltipItems[0].index];
        const total = dataset.data.reduce((sum, value) => sum + value, 0);
        const percentage = Math.round((1000 * value) / total) / 10;
        return `${percentage} %`;
      },
    },
    ...tooltipColorSettings,
  },
};

export default PieChart;

PieChart.prototypes = {
  series: arrayOf(shape([string, number])).isRequired,
};

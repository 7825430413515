import t from "../lib/translate";

const PaginationInfo = ({ page, itemsPerPage, totalNumberOfItems }) => {
  if (totalNumberOfItems === 0) {
    return null;
  }

  return t(`Visar {:from} till {:to} av totalt {:total} rader`, {
    from: (page - 1) * itemsPerPage + 1,
    to: Math.min(page * itemsPerPage, totalNumberOfItems),
    total: totalNumberOfItems,
  });
};

export default PaginationInfo;

import React from "react";
import PropTypes from "prop-types";

import PaginationRow from "./PaginationRow";
import LoadWrapper from "./LoadWrapper";
import ListSettings from "./ListSettings";
import ListHeaderCell from "./ListHeaderCell";

import t from "../lib/translate";

const FacilityPartList = (props) => {
  return (
    <React.Fragment>
      <ListSettings
        filter={props.filter}
        onFilterChange={(event) => props.onFilterChange(event.target.value)}
        onPerPageChange={(event) =>
          props.onPerPageChange(parseInt(event.target.value, 10))
        }
        perPage={props.perPage}
        ariaControls="facilityPart-list"
      />

      <LoadWrapper isLoading={props.isLoading} key={props.isLoading ? 1 : 0}>
        <table
          className="table table-hover pl-data-table"
          id="facilityPart-list"
        >
          <thead>
            <tr>
              {[
                { field: "name", title: t("Benämning") },
                { field: "amount", title: t("Antal") },
                { field: "unit", title: t("Enhet") },
              ].map((headerItem) => (
                <ListHeaderCell
                  key={headerItem.field}
                  {...headerItem}
                  sorting={props.sorting}
                  onSortChange={props.onSortChange}
                />
              ))}
            </tr>
          </thead>

          <tbody>
            {props.items.map((facilityPart) => (
              <tr
                onClick={() => props.onSelect(facilityPart)}
                onKeyPress={(e) =>
                  e.key == "Enter" && props.onSelect(facilityPart)
                }
                className="clickable"
                key={facilityPart.id}
                tabIndex="0"
              >
                <td>{facilityPart.name}</td>
                <td>{facilityPart.amount}</td>
                <td>{facilityPart.unit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </LoadWrapper>

      <PaginationRow
        page={props.page}
        itemsPerPage={props.perPage}
        totalNumberOfItems={props.total}
        setPage={props.onPageChange}
        ariaControls="facilityPart-list"
      />
    </React.Fragment>
  );
};

FacilityPartList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      unit: PropTypes.string,
      amount: PropTypes.number,
    })
  ).isRequired,
  filter: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  onSortChange: PropTypes.func,
  onPerPageChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onSelect: PropTypes.func,
};

export default FacilityPartList;

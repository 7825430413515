import API from "./lib/API";
import { qs } from "./lib/helpers";

const noop = () => {};

const defaultOptions = {
  formSelector: "form",
  onLoad: noop,
  onSuccess: noop,
};

const init = (options = {}) => {
  const opts = Object.assign({}, defaultOptions, options);
  const form = document.querySelector(opts.formSelector);

  form.addEventListener("submit", (event) => {
    event.preventDefault();
    const formData = new FormData(form);
    const queryString = qs(Object.fromEntries(formData.entries()));

    opts.onLoad();

    (async () => {
      const { status } = await fetchJSON(`${form.action}?${queryString}`);

      const interval = setInterval(async () => {
        const response = await API.get(status);
        const exportStatus = await response.json();

        if (exportStatus.ok) {
          clearInterval(interval);
          return opts.onComplete(exportStatus.url);
        }
      }, 2500);
    })();
  });
};

const fetchJSON = async (url) => {
  const response = await fetch(url, {
    headers: {
      Accept: "application/json",
    },
  });
  return await response.json();
};

export default { init };

import React from "react";
import { bool, shape, string } from "prop-types";
import { filterType } from "../types";
import DepositionAnalysis from "./DepositionAnalysis";
import { getFilterQueryObject } from "../lib/maintenance_plan_action";
import AnalysisPage from "./AnalysisPage";
import useAPI from "../hooks/useAPI";
import useBooleanSwitch from "../hooks/useBooleanSwitch";
import useTimestamp from "../hooks/useTimestamp";
import Loader from "./Loader";

const AnalysisPageContainer = ({
  allowMultiplePlans,
  filters,
  isReadOnly,
  isRecalculating,
  plan,
  includeVAT,
}) => {
  const [timestamp, touchTimestamp] = useTimestamp();
  const { data } = useAPI("/maintenance_plan_actions", {
    query: {
      ...getFilterQueryObject({ ...filters, text: "" }),
      include_vat: includeVAT,
      page: { limit: 0 },
    },
    lastUpdate: timestamp,
  });

  useBooleanSwitch(isRecalculating, { offCallback: touchTimestamp });

  if (!data) {
    return <Loader />;
  }

  const {
    building_share,
    facility_share,
    category_share,
    average_cost_per_year_and_area,
    status_share,
    total_cost,
    maintenance_debt,
    average_maintenance_debt_per_area,
    benchmark_maintenance_debt_per_area,
    investment_cost,
    maintenance_cost,
  } = data.meta.analysis;

  return (
    <React.Fragment>
      <AnalysisPage
        allowMultiplePlans={allowMultiplePlans}
        buildingShare={building_share}
        facilityShare={facility_share}
        categoryShare={category_share}
        costPerYearAndArea={average_cost_per_year_and_area}
        statusShare={status_share}
        yearlyAverageCost={data.meta.yearly_average_cost}
        yearlyCosts={data.meta.yearly_costs_by}
        yearlyCostsByActionType={data.meta.yearly_costs_by_action_type}
        totalCost={total_cost}
        maintenanceDebt={maintenance_debt}
        averageMaintenanceDebtPerArea={average_maintenance_debt_per_area}
        benchmarkMaintenanceDebtPerArea={benchmark_maintenance_debt_per_area}
        investmentCost={investment_cost}
        maintenanceCost={maintenance_cost}
      />
      {plan && plan.attributes.settings.show_deposition && (
        <>
          <hr />
          <DepositionAnalysis
            isReadOnly={isReadOnly}
            plan={plan}
            isRecalculating={isRecalculating}
            includeVAT={includeVAT}
          />
        </>
      )}
    </React.Fragment>
  );
};

const createConditionalRender = (conditionFn, elementTrue, elementFalse) => {
  return (props) => {
    if (conditionFn(props)) {
      return elementTrue(props);
    } else {
      return elementFalse(props);
    }
  };
};

export default createConditionalRender(
  (props) => Boolean(props.filters),
  AnalysisPageContainer,
  Loader
);

AnalysisPageContainer.propTypes = {
  allowMultiplePlans: bool.isRequired,
  filters: filterType.isRequired,
  isReadOnly: bool.isRequired,
  isRecalculating: bool.isRequired,
  plan: shape({ id: string }),
};

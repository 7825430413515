import React from "react";
import { node, string } from "prop-types";
import Tooltip from "./Tooltip";

const KPIBox = ({ children, extras = [], title, tooltip }) => (
  <div className="ibox ibox-kpi">
    <div className="ibox-title">
      <h5>{title}</h5>
      {tooltip && <Tooltip text={tooltip} />}
    </div>
    <div className="ibox-content">
      {children}
      {extras.length > 0 ? (
        <ul className="extras">
          {extras
            .filter((item) => Boolean(item))
            .map((item, index) => (
              <li key={index}>{item}</li>
            ))}
        </ul>
      ) : null}
    </div>
  </div>
);

export default KPIBox;

KPIBox.propTypes = {
  children: node,
  title: string,
  tooltip: string,
};

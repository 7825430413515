import ModalWithReactComponentController from "./modal_with_react_component_controller";
import React from "react";

import ActionSuggestionModal from "../components/ActionSuggestionModal";

export default class extends ModalWithReactComponentController {
  static targets = ["name"];
  static values = {
    rootScope: String,
  };

  getReactComponent = () => {
    return React.createElement(ActionSuggestionModal, {
      onSelect: this.onSelect,
      onClose: this.hide,
    });
  };

  onSelect = (item) => {
    this.hide();
    const fieldValues = Object.assign({}, item.attributes, {
      maintenance_plan_action_suggestion_id: item.id,
    });

    this.nameTarget.innerText = `”${fieldValues.name}”`;

    for (const key of Object.keys(fieldValues)) {
      const element = document
        .querySelector(this.rootScopeValue)
        .querySelector(`[name="maintenance_plan_action[${key}]"`);

      if (element) {
        element.value = fieldValues[key];
        element.dispatchEvent(new Event("change"));
      }
    }
  };
}

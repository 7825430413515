import React from "react";
import SuggestionListContainer from "./SuggestionListContainer";
import Modal from "./Modal";

import t from "../lib/translate";

const ActionSuggestionModal = (props) => {
  return (
    <Modal
      includeDialog={false}
      title={t("Åtgärdsförslag")}
      onClose={props.onClose}
    >
      <SuggestionListContainer
        onSelect={props.onSelect}
        initialFilter={props.initialFilter}
      />
    </Modal>
  );
};

export default ActionSuggestionModal;

import * as Yup from "yup";
import API from "./lib/API";
import { defaults } from "react-chartjs-2";

API.addErrorHandler(async (response) => {
  // Invalid or expired API token.
  if (response.status === 401) {
    // Begin page reload and halt execution
    window.location.reload();
    await new Promise(() => {});
  }
});

Yup.setLocale({
  mixed: {
    default: "Fältet är ogiltigt",
    required: "Fältet är obligatoriskt",
    notType: "Fältet är ogiltigt",
  },
  number: {
    min: "Värdet måste vara större än eller lika med ${min}",
    max: "Värdet måste vara mindre än eller lika med ${max}",
    lessThan: "Värdet måste vara mindre än ${less}",
    moreThan: "Värdet måste vara mindre än ${more}",
    positive: "Värdet måste vara större än 0",
    negative: "Värdet måste vara mindre än 0",
    integer: "Värdet måste vara ett heltal",
  },
  string: {
    max: "Texten får inte vara längre än ${max} tecken.",
  },
});

defaults.global.defaultFontFamily =
  "'Funkis', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

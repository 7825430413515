import React, { useState } from "react";

import MultiSelect from "@khanacademy/react-multi-select";

import t from "../lib/translate";

const EMPTY_OPTION_VALUE = 0;

const filterOptions = (options, filter) =>
  filter == ""
    ? options
    : options.filter(
        (option) =>
          option.label.toLowerCase().indexOf(filter.toLowerCase()) > -1
      );

const MultiSelectField = ({
  defaultValues = [],
  emptyOption,
  label,
  name,
  options,
  disableSearch = false,
  hasSelectAll = true,
  singleFormValue = false,
}) => {
  const [values, setValues] = useState(
    singleFormValue
      ? defaultValues.map((id) => parseInt(id, 10))
      : defaultValues
  );
  let selected = values;

  if (emptyOption) {
    options = [{ value: EMPTY_OPTION_VALUE, label: emptyOption }].concat(
      options
    );
    selected = values.map((value) =>
      value == "0" ? EMPTY_OPTION_VALUE : value
    );
  }

  const handleSpaceWorkaround = (e) => {
    if (
      e.key === " " &&
      e.target.tagName === "INPUT" &&
      e.target.type === "text"
    ) {
      e.stopPropagation();
    }
  };

  return (
    <div onKeyDownCapture={handleSpaceWorkaround}>
      <MultiSelect
        disableSearch={disableSearch}
        filterOptions={filterOptions}
        hasSelectAll={hasSelectAll}
        onSelectedChanged={setValues}
        options={options}
        overrideStrings={{
          selectSomeItems: t(`Välj ${label.toLowerCase()}`),
          allItemsAreSelected: t("Alla valda"),
          selectAll: t("Välj alla"),
          search: t("Sök"),
        }}
        ItemRenderer={CustomItemRenderer}
        selected={selected}
      />

      {singleFormValue ? (
        <input type="hidden" name={name} value={values.join(",")} />
      ) : (
        values.map((value) => (
          <input type="hidden" name={name} key={value} value={value} />
        ))
      )}
    </div>
  );
};

// This is mostly a copy of the DefaultItemRenderer from select-item.js in react-multi-select
// but with a change so that the Select all option is bold.
const CustomItemRenderer = (props) => {
  const { checked, option, onClick, disabled } = props;

  const styles = {
    label: {
      display: "inline-block",
      verticalAlign: "middle",
      borderBottomRightRadius: "2px",
      borderTopRightRadius: "2px",
      cursor: "default",
      padding: "2px 5px",
    },
    labelDisabled: {
      opacity: 0.5,
    },
    labelSelectAll: {
      fontWeight: "bold",
    },
  };

  const style = {
    ...styles.label,
    ...(disabled ? styles.labelDisabled : undefined),
    ...(option.value === "" ? styles.labelSelectAll : undefined),
  };

  return (
    <span className="item-renderer">
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex="-1"
        disabled={disabled}
      />
      <span style={style}>{option.label}</span>
    </span>
  );
};

export default MultiSelectField;

import React from "react";
import { func, number, string } from "prop-types";

import t from "../lib/translate";
import { classSet } from "../lib/helpers";

const MAX_PAGINATION_ITEMS = 7;
const SEPARATOR = "SEPARATOR";

const PaginationControls = ({
  total,
  perPage,
  page,
  ariaControls,
  onPageChange,
}) => {
  const numPages = total === 0 ? 1 : Math.ceil(total / perPage);
  const pageItems = getDisplayedPageItems({ page, total, numPages });

  return (
    <ul className="pagination">
      <NavItem
        ariaControls={ariaControls}
        direction="previous"
        disabled={page === 1}
        nextPage={page - 1}
        onPageChange={onPageChange}
        label={t("Föregående")}
      />
      {pageItems.map((pageItem, idx) => (
        <PageNumberItem
          currentPage={page}
          page={pageItem}
          onPageChange={onPageChange}
          ariaControls={ariaControls}
          key={pageItem === SEPARATOR ? `sep${idx}` : pageItem}
        />
      ))}
      <NavItem
        ariaControls={ariaControls}
        direction="next"
        disabled={page === numPages}
        nextPage={page + 1}
        onPageChange={onPageChange}
        label={t("Nästa")}
      />
    </ul>
  );
};

const NavItem = ({
  ariaControls,
  direction,
  disabled,
  nextPage,
  onPageChange,
  label,
}) => {
  return (
    <li
      className={classSet("paginate_button", direction, {
        disabled: disabled,
      })}
      aria-controls={ariaControls}
      tabIndex="0"
      onClick={() => !disabled && onPageChange(nextPage)}
    >
      <a href="#">{label}</a>
    </li>
  );
};

const PageNumberItem = ({ currentPage, page, onPageChange, ariaControls }) => {
  const classNames = {
    paginate_button: true,
    disabled: page === SEPARATOR,
    active: page === currentPage,
  };
  const onClick = page === SEPARATOR ? () => {} : () => onPageChange(page);

  return (
    <li
      className={classSet(classNames)}
      aria-controls={ariaControls}
      tabIndex="0"
      onClick={onClick}
    >
      <a href="#">{page === SEPARATOR ? "..." : page}</a>
    </li>
  );
};

const getDisplayedPageItems = ({ page, total, numPages }) => {
  if (total === 0) {
    return [];
  }

  let pages = [];
  for (let i = 1; i <= numPages; i++) {
    pages.push(i);
  }

  if (numPages <= MAX_PAGINATION_ITEMS) {
    return pages;
  }

  if (page < MAX_PAGINATION_ITEMS - 2) {
    return pages
      .slice(0, MAX_PAGINATION_ITEMS - 2)
      .concat([SEPARATOR, numPages]);
  }

  if (page > numPages - MAX_PAGINATION_ITEMS + 3) {
    return [1, SEPARATOR].concat(
      pages.slice(numPages - MAX_PAGINATION_ITEMS + 2)
    );
  }

  return [1, SEPARATOR]
    .concat(pages.slice(page - 2, page + 1))
    .concat([SEPARATOR, numPages]);
};

PaginationControls.propTypes = {
  ariaControls: string.isRequired,
  page: number.isRequired,
  total: number.isRequired,
  perPage: number.isRequired,
  onPageChange: func.isRequired,
};

export default PaginationControls;

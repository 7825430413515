import { Controller } from "stimulus";
import ReactDOM from "react-dom";

import Modal from "../lib/modal";

export default class extends Controller {
  initialize() {
    this.modal = new Modal();
  }

  connect() {
    document.body.addEventListener("keyup", this.handleKeyPress);
  }

  disconnect() {
    document.body.removeEventListener("keyup", this.handleKeyPress);
  }

  show = () => {
    ReactDOM.render(this.getReactComponent(), this.getReactRootNode());
    this.previousActiveElement = document.activeElement;
    this.modal.show();
  };

  hide = () => {
    this.modal.hide();
    ReactDOM.unmountComponentAtNode(this.getReactRootNode());

    if (this.previousActiveElement) {
      this.previousActiveElement.focus();
      this.previousActiveElement = null;
    }
  };

  getReactRootNode() {
    return this.modal.getModalElement().querySelector(".modal-content");
  }

  handleKeyPress = (e) => {
    if (e.key == "Escape" && this.modal.isVisible) {
      this.modal.hide();
    }
  };
}

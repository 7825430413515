import React, { useRef } from "react";
import { string } from "prop-types";

/*global $ */
const Tooltip = ({ children, icon, text, style = {} }) => {
  const el = useRef();
  if (el.current) {
    $(el.current).tooltip();
  }

  const defaultIcon = "fa-info-circle";

  return (
    <span style={{ marginLeft: "5px", ...style }}>
      <i
        className={children ? "" : `fa ${icon || defaultIcon}`}
        data-html="true"
        data-original-title={text} // Bootstrap Tooltip displays the value of this attribute
        style={{ fontStyle: "normal" }}
        ref={el}
      >
        {children}
      </i>
    </span>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  text: string,
  icon: string,
};

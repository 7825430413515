export default class {
  constructor() {
    this.isVisible = false;
    this.modalElement = this.createModal();
    this.backdrop = this.createBackdrop();
  }

  show() {
    document.body.appendChild(this.backdrop);
    this.modalElement.classList.add("block");
    this.isVisible = true;
    this.modalElement.focus();
  }

  hide() {
    if (!this.isVisible) {
      return;
    }

    this.modalElement.classList.remove("block");
    document.body.removeChild(this.backdrop);
    this.isVisible = false;
  }

  getModalElement() {
    return this.modalElement;
  }

  createModal() {
    document.body.insertAdjacentHTML(
      "beforeend",
      `<div role="dialog" aria-modal="true" class="modal" tabindex="-1">
        <div role="document" class="modal-dialog">
          <div class="modal-content"></div>
        </div>
      </div>`
    );
    return document.body.lastElementChild;
  }

  createBackdrop() {
    const backdrop = document.createElement("div");
    backdrop.className = "modal-backdrop in";

    return backdrop;
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileField", "image"];

  updateImage(e) {
    const image = e.target.files[0];

    if (image) {
      const reader = new FileReader();
      const element = this.imageTarget;

      reader.onload = function (e) {
        element.setAttribute("src", e.target.result);
      };

      reader.readAsDataURL(image);
    }
  }
}

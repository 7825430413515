import React from "react";
import { arrayOf, bool, string, shape, object } from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  getColor,
  getLabel,
  sumCostLabel,
  tooltipColorSettings,
  legendSettings,
  eventSettings,
  seriesToChartData,
  timeAxisSettings,
  costLabel,
} from "../lib/chartHelpers";
import { currency } from "../lib/helpers";
import t from "../lib/translate";

const TimeBarChart = ({
  series,
  stacked = true,
  maxLegendItems = 100,
  reverseStackingOrder = false,
  height = 300,
  preferredColors = {},
}) => {
  const datasets = series.map((serie, index) => ({
    id: serie.id,
    label: getLabel(serie.label),
    backgroundColor: getColor(index, getLabel(serie.label), preferredColors),
    borderWidth: 0,
    maxBarThickness: 50,
    data: seriesToChartData(serie.data),
  }));

  if (reverseStackingOrder) {
    datasets.reverse();
  }

  const getDatasetKey = (dataset) => {
    return dataset.id ? dataset.id : dataset.label;
  };

  return (
    <div style={{ height, marginTop: 20 }}>
      <Bar
        datasetKeyProvider={getDatasetKey}
        data={{ datasets }}
        options={{
          maintainAspectRatio: false,
          legend: {
            display: series.length > 1 && series.length < maxLegendItems,
            reverse: reverseStackingOrder,
            ...legendSettings,
          },
          ...eventSettings,
          tooltips: {
            position: "nearest",
            callbacks: {
              title: (items) =>
                `${t("År")} ${new Date(items[0].label).getFullYear()}`,
              label: (tooltipItem, data) => costLabel(tooltipItem, data),
              afterBody: (tooltipItems, data) => {
                if (data.datasets.length > 1 && stacked) {
                  return sumCostLabel(tooltipItems[0].index, data);
                }
              },
            },
            ...tooltipColorSettings,
          },
          scales: {
            xAxes: [
              {
                ...timeAxisSettings,
                stacked: stacked,
              },
            ],
            yAxes: [
              {
                stacked: stacked,
                ticks: {
                  callback: (value) => currency(value),
                  maxTicksLimit: 4,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default TimeBarChart;

TimeBarChart.propTypes = {
  series: arrayOf(shape({ data: object.isRequired, label: string })).isRequired,
  stacked: bool,
};

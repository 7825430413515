import { Controller } from "stimulus";
import TomSelect from "tom-select";
import { defaultConfig } from "../config/tom-select";

export default class extends Controller {
  static targets = ["source"];
  static values = {
    currentFacility: Number,
  };

  connect() {
    if (this.hasSourceTarget) {
      this.customDropdown = new TomSelect(this.sourceTarget, this.config());
    }
  }

  config() {
    let config = Object.assign({}, defaultConfig, {
      valueField: "id",
      labelField: "name",
      optgroupField: "organization_id",
      searchField: ["name", "organization_name"],
      create: false,
      maxItems: 1,
      maxOptions: null,
      onItemAdd: () => this.customDropdown.blur(),
      onDelete: () => {
        return false;
      },
      render: Object.assign({}, defaultConfig.render, {
        item: this.renderItem,
        optgroup_header: function (data, escape) {
          return '<div class="optgroup-header">' + escape(data.name);
        },
      }),
    });

    return config;
  }

  renderItem = (data, escape) => {
    return (
      "<div><strong>" +
      escape(data.organization_name) +
      "</strong>: " +
      escape(data.name) +
      "</div>"
    );
  };

  async loadFacilities() {
    const url = `/facilities/${this.currentFacilityValue}/copy_destinations`;

    const response = await fetch(url);
    const results = await response.json();

    results.forEach((item) => {
      this.customDropdown.addOptionGroup(item.id, item);
      this.customDropdown.addOptions(item.facilities);
    });

    this.customDropdown.addItem(this.currentFacilityValue);
  }
}

import React from "react";

import CKEditor from "ckeditor4-react";

const RichTextEditor = ({ text, selector }) => {
  return (
    <CKEditor
      data={text}
      onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
      config={{
        toolbar,
        format_tags: "p;h1;h2;h3",
        language: "sv",
        removeButtons: "",
        extraPlugins: "autogrow",
        autoGrow_bottomSpace: 50,
        autoGrow_maxHeight: 600,
        autoGrow_onStartup: true,
        disableNativeSpellChecker: false,
        versionCheck: false,
      }}
      onChange={(event) => {
        const data = event.editor.getData();
        document.querySelector(selector).value = data;
      }}
    />
  );
};

const toolbar = [
  { name: "styles", items: ["Format"] },
  { name: "basicstyles", items: ["Bold", "Italic", "Underline"] },
  { name: "links", items: ["Link", "Unlink"] },
  {
    name: "paragraph",
    items: ["NumberedList", "BulletedList", "Outdent", "Indent"],
  },
  { name: "insert", items: ["Table"] },
  { name: "clipboard", items: ["Undo", "Redo"] },
];

export default RichTextEditor;

import React, { useState } from "react";
import { number, string, oneOfType } from "prop-types";
import AttachmentListContainer from "./AttachmentListContainer";
import AttachmentDropzone from "./AttachmentDropzone";

import t from "../lib/translate";

const uploadAttachment = async (file, resourceType, actionId) => {
  const data = new FormData();
  data.append("authenticity_token", window.authToken());
  data.append("file", file);
  data.append("resource_type", resourceType);
  data.append("resource_id", actionId);

  const response = await fetch("/attachments", {
    // Does not use API
    method: "POST",
    body: data,
    credentials: "same-origin",
  });

  if (!response.ok) {
    let errors = [t("Ett fel uppstod vid uppladdningen.")];
    try {
      const body = await response.json();
      errors = body.errors;
    } catch (e) {
      console.error(e);
    }
    return { success: false, errors, file: file.name };
  }
  return { success: true, file: file.name };
};

const AttachmentView = ({
  maxUploadSizeMB,
  resourceId,
  resourceType,
  resourcePath,
  allowedExtensions = "",
  simpleList = false,
  isClone = false,
}) => {
  const MAX_SIZE = maxUploadSizeMB * 1000 * 1000;
  const [counter, setCounter] = useState(0);
  const [notice, setNotice] = useState(null);
  const showDropzone = !isClone;

  return (
    <>
      {notice && (
        <div className={`alert alert-${notice.alertType}`} role="alert">
          <div className="alert-content">
            <ul>
              {notice.messages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {showDropzone && (
        <AttachmentDropzone
          maxSize={MAX_SIZE}
          onUpload={(file) => uploadAttachment(file, resourceType, resourceId)}
          onUploadComplete={() => setCounter(counter + 1)}
          onNotice={setNotice}
          allowedExtensions={allowedExtensions}
        />
      )}

      <AttachmentListContainer
        simpleList={simpleList}
        key={counter}
        parentId={resourceId}
        resourcePath={resourcePath}
        onDelete={() =>
          setNotice({
            alertType: "success",
            messages: [t("Filen har tagits bort.")],
          })
        }
      />
    </>
  );
};

export default AttachmentView;

AttachmentView.propTypes = {
  maxUploadSizeMB: number.isRequired,
  resourceId: oneOfType([string, number]).isRequired,
  resourceType: string.isRequired,
  resourcePath: string.isRequired,
  allowedExtensions: string,
};

import React from "react";
import { useState } from "react";
import { arrayOf, array, string, shape, object, number } from "prop-types";
import { currency } from "../lib/helpers";
import t from "../lib/translate";

const FacilitiesTable = ({ facilities, series, total, years }) => {
  if (!facilities) {
    return <i className="fa fa-spinner fa-spin fa-spin-big" />;
  }

  return (
    <FilteredTable
      facilities={facilities}
      series={series}
      total={total}
      years={years}
    />
  );
};

const FilteredTable = ({ facilities, total, series, years }) => {
  const [filteredFacilities, setFilteredFacilities] = useState(facilities);

  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          {facilities.length > 3 && (
            <FilterByNameField
              setFilteredItems={setFilteredFacilities}
              items={facilities}
            />
          )}
        </div>
      </div>
      <div style={{ overflow: "scroll" }}>
        <table
          data-cy="facilities-table"
          className="table table-hover table-bordered padded-cells"
        >
          <TableHeader headers={years} />
          <tbody>
            <TableRows
              series={series}
              rows={filteredFacilities}
              numberOfColums={years.length}
            />
            {total && (
              <TableRow
                name={t("Totalt per år")}
                costs={Object.values(total)}
                bold={true}
              />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const FilterByNameField = ({ setFilteredItems, items }) => (
  <div className="input-group form-group">
    <div className="input-group-addon">
      <i className="fa fa-search" />
    </div>
    <input
      type="text"
      className="form-control"
      placeholder={t("Sök...")}
      onChange={(e) =>
        setFilteredItems(
          items.filter((item) =>
            item.attributes.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        )
      }
    />
  </div>
);

const TableHeader = ({ headers }) => (
  <thead>
    <tr>
      <th />
      {headers.map((header) => (
        <th className="text-center" key={header}>
          {header}
        </th>
      ))}
      <th className="text-right">{t("Totalt")}</th>
    </tr>
  </thead>
);

const TableRows = ({ series, rows, numberOfColums }) => {
  const findById = (id) => series.find((obj) => obj.id === id);

  return rows.map((item) => {
    const id = item.id;
    const name = item.name;
    const costsBy = findById(item.id);
    const placeholder = () => new Array(numberOfColums).fill(0);

    return (
      <TableRow
        key={id}
        name={name}
        linkTo={`/facilities/${id}`}
        costs={costsBy ? Object.values(costsBy.data) : placeholder()}
        tags={item.facility_tags}
        keyId={id}
      />
    );
  });
};

const TableRow = ({ name, costs, linkTo, tags, keyId, bold = false }) => {
  const sum = (values) => values.reduce((acc, current) => acc + current, 0);

  return (
    <tr>
      <th className="nowrap">
        {linkTo ? <a href={linkTo}>{name}</a> : name}
        {tags && tags.map((tag, i) => <Tag name={tag} key={`${keyId}-${i}`} />)}
      </th>
      {costs.map((cost, i) => (
        <td
          className="text-right nowrap"
          style={{
            fontWeight: bold ? "bold" : "normal",
          }}
          key={i}
        >
          {currency(cost, true)}
        </td>
      ))}
      <td style={{ fontWeight: "bold" }} className="text-right nowrap">
        {currency(sum(costs), true)}
      </td>
    </tr>
  );
};

const Tag = ({ name }) => (
  <>
    {" "}
    <span className="label label-tag">{name}</span>
  </>
);

export default FacilitiesTable;

FacilitiesTable.propTypes = {
  series: arrayOf(shape({ data: object.isRequired, id: number })).isRequired,
  total: object,
  facilities: array,
};

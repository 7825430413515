import ModalWithReactComponentController from "./modal_with_react_component_controller";
import React from "react";

import FacilityPartModal from "../components/FacilityPartModal";

export default class extends ModalWithReactComponentController {
  getReactComponent = () => {
    const planID = document.querySelector(
      "#maintenance_plan_action_maintenance_plan_id"
    )?.value;

    return React.createElement(FacilityPartModal, {
      onSelect: this.onSelect,
      onClose: this.hide,
      maintenancePlanId: planID,
    });
  };

  onSelect = (item) => {
    this.hide();

    const unitElement = document.querySelector(
      `[name="maintenance_plan_action[unit]"`
    );
    if (unitElement.readOnly && unitElement.value !== item.unit) {
      return;
    }
    unitElement.value = item.unit;
    unitElement.dispatchEvent(new Event("change"));

    const amountElement = document.querySelector(
      `[name="maintenance_plan_action[amount]"`
    );
    amountElement.value = item.amount;
    amountElement.dispatchEvent(new Event("change"));
  };
}

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { fields: Array };

  connect() {
    this.toggle();
  }

  toggle() {
    this.fieldsValue.forEach((selector) => {
      document.querySelectorAll(selector).forEach((field) => {
        if (field.dataset?.readOnlyLocked == "true") {
          return;
        }

        const attribute = field.type == "checkbox" ? "disabled" : "readOnly";

        field[attribute] = this.element.checked;
      });
    });
  }
}

import AutocompleteController from "./autocomplete_controller";

export default class extends AutocompleteController {
  static targets = ["input", "results"];
  static values = {
    actionId: { type: String, default: "" },
  };

  MENTION_REGEX = /@\S+/g;

  onKeyDown(e) {
    if (this.resultsTarget.classList.contains("hidden")) {
      return;
    }

    super.onKeyDown(e);
  }

  async search() {
    if (this.shouldHideResults()) {
      this.hideResults();
      return;
    }

    super.search(
      `/actions/${this.actionIdValue}/users/autocomplete`,
      this.currentQuery()
    );
  }

  shouldHideResults() {
    const matchesOnMentionInCurrentWord =
      this.currentWord().match(this.MENTION_REGEX) || [];

    return matchesOnMentionInCurrentWord.length !== 1;
  }

  currentQuery() {
    const currentWordWithoutLeadingAtAndDelimiters = this.currentWord()
      .slice(1)
      .replace(/[\.,!\?:;]+$/, "");

    return currentWordWithoutLeadingAtAndDelimiters;
  }

  currentWord() {
    const indexSpaceBeforeCursor = this.inputTarget.value
      .slice(0, this.inputTarget.selectionStart)
      .lastIndexOf(" ");
    const indexNewlineBeforeCursor = this.inputTarget.value
      .slice(0, this.inputTarget.selectionStart)
      .lastIndexOf("\n");
    const indexSpaceBeforeCursorOrStartOfInput = Math.max(
      indexSpaceBeforeCursor,
      indexNewlineBeforeCursor,
      0
    );

    const indexSpaceAfterCursor = this.inputTarget.value.indexOf(
      " ",
      this.inputTarget.selectionStart
    );
    const indexSpaceAfterCursorOrEndOfInput = Math.max(
      indexSpaceAfterCursor,
      this.inputTarget.selectionStart
    );

    let word = this.inputTarget.value.slice(
      indexSpaceBeforeCursorOrStartOfInput,
      indexSpaceAfterCursorOrEndOfInput
    );
    if (word[0] === " " || word[0] === "\n") {
      word = word.slice(1);
    }

    return word;
  }

  onSelect = (email) => {
    const newCaretPosition = this.replaceValuefromIndex() + email.length + 1;
    this.inputTarget.value = this.replaceQueryWithSelectedValue(email);
    this.inputTarget.focus();
    this.inputTarget.setSelectionRange(newCaretPosition, newCaretPosition);
    this.hideResults();
  };

  replaceQueryWithSelectedValue(email) {
    return `${this.inputTarget.value.slice(
      0,
      this.replaceValuefromIndex()
    )}${email} ${this.inputTarget.value.slice(this.replaceValueToIndex())}`;
  }

  replaceValuefromIndex() {
    return this.inputTarget.value
      .slice(0, this.inputTarget.selectionStart)
      .lastIndexOf(this.currentQuery());
  }

  replaceValueToIndex() {
    return (
      this.inputTarget.value
        .slice(0, this.inputTarget.selectionStart)
        .lastIndexOf(this.currentQuery()) + this.currentQuery().length
    );
  }
}

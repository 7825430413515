import { Controller } from "stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static values = { debounceTime: Number };

  initialize() {
    if (this.hasDebounceTimeValue) {
      this.submit = debounce(this.submit, this.debounceTimeValue).bind(this);
    }
  }

  submit(event) {
    event.target.closest("form").requestSubmit();
  }
}

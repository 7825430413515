const addToProject = () => {
  const modal = window.$("#modal-add-project");
  if (!modal) {
    return;
  }

  modal.on("show.bs.modal", (e) => {
    const actionId = e.relatedTarget.dataset.actionId;
    const projectName = e.relatedTarget.dataset.projectName;
    const dropDown = document.getElementById("project_name").tomselect;

    dropDown.clear();

    document.querySelector(
      "#modal-add-project input[type='hidden'][name='action_id']"
    ).value = actionId;

    if (projectName) {
      dropDown.addItem(projectName);
    }
  });
};

window.addEventListener("DOMContentLoaded", addToProject);

import React, { useState, useEffect } from "react";
import useBooleanSwitch from "../hooks/useBooleanSwitch";
import useTimestamp from "../hooks/useTimestamp";
import UIBox from "./UIBox";
import DepositionChart from "./DepositionChart";
import DepositionSettings from "./DepositionSettings";
import KPIBox from "./KPIBox";

import MaintenancePlan from "../lib/MaintenancePlan";
import t from "../lib/translate";
import { qs } from "../lib/helpers";

const DepositionAnalysis = (props) => {
  const [deposition, setDeposition] = useState({});
  const [settingsUpdated, touchSettingsUpdated] = useTimestamp();
  const { plan, includeVAT } = props;
  const facilityId = plan?.attributes?.facility_id;

  const onChange = async (attributes) => {
    await MaintenancePlan.update(plan.id, attributes);
    touchSettingsUpdated();
  };

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `/facilities/${facilityId}/plans/${
          plan.id
        }/actions/deposition_analysis?${qs({ include_vat: includeVAT })}`
      );
      const data = await response.json();
      setDeposition(data);
    })();
  }, [plan.id, facilityId, settingsUpdated, includeVAT]);

  useBooleanSwitch(props.isRecalculating, {
    offCallback: touchSettingsUpdated,
  });

  return (
    <>
      <UIBox>
        <h2>{t("Avsättningsanalys")}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Avsättningsanalysen visar <strong>hela din underhållsplan</strong> och <strong>exkluderar investeringsåtgärder</strong>. Eventuella filter som ställts in påverkar inte analysen."
            ),
          }}
        />

        <div style={{ height: "400px", width: "100%" }}>
          <DepositionChart data={getDepositionChartData(deposition)} />
        </div>
      </UIBox>

      <div className="row">
        <div className="col-md-6">
          <KPIBox title={t("Rekommenderad avsättning per år och m² (BOA)")}>
            <h1>
              {deposition.alt_deposition_per_residential_area
                ? deposition.alt_deposition_per_residential_area
                : "-"}{" "}
              {t("kr")} / m&sup2;
            </h1>
          </KPIBox>
        </div>
        <div className="col-md-6">
          <KPIBox title={t("Nuvarande avsättning per per år och m² (BOA)")}>
            <h1>
              {deposition.deposition_per_residential_area
                ? deposition.deposition_per_residential_area
                : "-"}{" "}
              {t("kr")} / m&sup2;
            </h1>
          </KPIBox>
        </div>
      </div>

      <hr />

      <DepositionSettings {...props} onChange={onChange} />
    </>
  );
};

const getDepositionChartData = (data) => {
  return {
    years: data.years,
    series: [
      {
        name: t("Fondsaldo vid rekommenderad avsättning"),
        data: data.alt_sums,
      },
      {
        name: t("Fondsaldo vid nuvarande avsättning"),
        data: data.sums,
      },
    ],
  };
};

export default DepositionAnalysis;

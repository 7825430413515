import React from "react";
import PropTypes from "prop-types";

import t from "../lib/translate";

import MultipleMaintenancePlanSelect from "./MultipleMaintenancePlanSelect";

const MaintenancePlanSelect = ({ onChange, value }) => {
  const addCursorPointerStyle = (provided) => ({
    ...provided,
    cursor: "pointer",
  });

  return (
    <MultipleMaintenancePlanSelect
      id="maintenance-plan-select"
      styles={{
        container: (provided) => ({ ...provided, flexGrow: 1 }),
        menuPortal: (provided) => ({ ...provided, zIndex: 2050 }),
        clearIndicator: addCursorPointerStyle,
        dropdownIndicator: addCursorPointerStyle,
        multiValueRemove: addCursorPointerStyle,
      }}
      onChange={onChange}
      menuPortalTarget={document.querySelector("body")}
      value={value}
      getOptionLabel={OptionLabel}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue.length === 0) {
          return t("Skriv något för att börja söka...");
        }
        return t("Inga resultat");
      }}
      loadingMessage={() => t("Söker...")}
    />
  );
};

const OptionLabel = (props) => {
  const optionTypes = {
    organization: { icon: "sitemap", label: t("Organisation") },
    facility: { icon: "building", label: t("Fastighet") },
    facility_tag: { icon: "tag", label: t("Fastighetstagg") },
  };
  const optionType = optionTypes[props.type];

  return (
    <span>
      <strong>
        <i className={`fa fa-${optionType.icon}`} /> {optionType.label}
      </strong>
      : {props.label}
    </span>
  );
};

export default MaintenancePlanSelect;

MaintenancePlanSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

import { currency } from "./helpers";
import t from "../lib/translate";
import colors from "../../assets/stylesheets/colors.scss";
import { STATUS_OPTIONS } from "../lib/maintenance_plan_action";

const tooltipColorSettings = {
  displayColors: false,
  backgroundColor: "rgba(235,235,235,0.9)",
  titleFontColor: "#000",
  bodyFontColor: "#000",
};

const legendSettings = {
  position: "bottom",
  labels: { boxWidth: 15, fontSize: 13 },
  onHover: (event) => {
    event.target.style.cursor = "pointer";
  },
};

const eventSettings = {
  events: ["mousemove", "click"],
  onHover: (event) => {
    event.target.style.cursor = "default";
  },
};

const CHART_COLORS = [
  "rgba(255, 185, 42, 1)",
  "rgba(51, 51, 51, 1)",
  "rgba(243, 104, 14, 1)",
  "rgba(10, 142, 142, 1)",
  "rgba(6, 192, 0, 1)",
  "rgba(38, 238, 178, 1)",
  "rgba(197, 2, 177, 1)",
  "rgba(175, 175, 255, 1)",
  "rgba(251, 98, 135, 1)",
  "rgba(38, 178, 255, 1)",
  "rgba(37, 0, 255, 1)",
  "rgba(204, 204, 204, 1)",
  "rgba(205, 249, 94, 1)",
  "rgba(169, 113, 0, 1)",
  "rgba(216, 192, 106, 1)",
  "rgba(255, 176, 242, 1)",
  "rgba(255, 230, 192, 1)",
];

const getChartColorsFromStatuses = (statuses) => {
  const colorMap = {
    prioritized: colors.red,
    deferred: colors.yellow,
    decided: colors.blue,
    in_progress: colors.light_blue,
    completed: colors.green,
    inactive: colors.light_gray,
    planned: colors.light_gray,
  };

  return statuses.map((status) => {
    const item = STATUS_OPTIONS.find((option) => option.label == status);
    return colorMap[item.value];
  });
};

const timeAxisSettings = {
  gridLines: { display: false },
  type: "time",
  time: { unit: "year" },
  distribution: "linear",
  offset: true,
  ticks: { autoSkip: true, source: "data" },
};

const getColor = (index, label = null, preferredColors = {}) => {
  if (preferredColors.hasOwnProperty(label)) {
    return preferredColors[label];
  }
  return CHART_COLORS[
    (index + Object.keys(preferredColors).length) % CHART_COLORS.length
  ];
};

const getLabel = (label) => {
  switch (label) {
    case "maintenance":
      return t("Underhåll");
    case "investment":
      return t("Investering");
    case null:
      return t("Okategoriserat");
    default:
      return label;
  }
};

const seriesToChartData = (data) =>
  Object.keys(data).map((year) => ({
    x: new Date(year),
    y: data[year],
  }));

const sumCostLabel = (index, data) => {
  const sum = data.datasets.reduce(
    (sum, dataset) => sum + dataset.data[index].y,
    0
  );
  return `${t("Totalt")}: ${currency(sum, true)}`;
};

const costLabel = (tooltipItem, data) => {
  const dataset = data.datasets[tooltipItem.datasetIndex];
  const cost = currency(dataset.data[tooltipItem.index].y, true);

  return dataset.label ? `${getLabel(String(dataset.label))}: ${cost}` : cost;
};

export {
  getColor,
  getLabel,
  CHART_COLORS,
  getChartColorsFromStatuses,
  sumCostLabel,
  tooltipColorSettings,
  legendSettings,
  eventSettings,
  seriesToChartData,
  timeAxisSettings,
  costLabel,
};

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { targetId: String };
  static targets = ["category", "prev", "next"];

  initialize() {
    const currentIndex = this.currentIndexFromHash();
    const category = this.categoryTargets[currentIndex];

    this.handleStepperDisabledState();

    if (this.boundingClient(category).visible) {
      return;
    }

    this.scrollCategories(this.boundingClient(category).show);
  }

  step(e) {
    const directionIsNext =
      e.target.closest("button") == this.nextTarget ? true : false;
    const currentIndex = this.currentIndexFromHash();
    const categoryIndex = directionIsNext ? currentIndex + 1 : currentIndex - 1;
    const category = this.categoryTargets[categoryIndex];

    if (!category) {
      return;
    }

    this.triggerClick(category, directionIsNext);
  }

  categoryClick(e) {
    const currentIndex = Array.from(this.categoryTargets).indexOf(
      e.target.closest("li")
    );

    this.handleStepperDisabledState(currentIndex);
  }

  handleStepperDisabledState(currentIndex = null) {
    const index = currentIndex || this.currentIndexFromHash();

    this.prevTarget.disabled = index == 0;
    this.nextTarget.disabled = index == this.categoryTargets.length - 1;
  }

  triggerClick(category, directionIsNext) {
    category.click();
    this.handleStepperDisabledState();

    if (this.boundingClient(category).visible) {
      return;
    }

    this.scrollCategories(
      this.boundingClient(category).scrollValue,
      directionIsNext
    );
  }

  scrollCategories(scrollValue, directionIsNext = true) {
    const categoriesContainer = this.categoryTarget.parentElement;

    if (directionIsNext) {
      categoriesContainer.scrollLeft += scrollValue;
    } else {
      categoriesContainer.scrollLeft -= scrollValue;
    }
  }

  boundingClient(el) {
    const parent = el.parentElement.getBoundingClientRect();
    const child = el.getBoundingClientRect();

    return {
      visible: child.left >= parent.left && child.right <= parent.right,
      scrollValue: child.right - child.left,
      show: child.left - (child.right - child.left),
    };
  }

  currentIndexFromHash = () => {
    if (window.location.hash == "") {
      return 0;
    }

    const [_, categoryId] = window.location.hash.split("#");
    const activeCategory = document.querySelector(
      `[data-target-id=${categoryId}]`
    );

    return Array.from(this.categoryTargets).indexOf(activeCategory);
  };
}

import { Controller } from "stimulus";
import PageLoader from "../lib/page_loader";

export default class extends Controller {
  static values = { interval: Number };

  connect() {
    this.startTimer();
  }

  disconnect() {
    this.stopTimer();
  }

  intervalValueChanged() {
    if (this.timer) {
      this.stopTimer();
      this.startTimer();
    }
  }

  startTimer() {
    if (this.hasIntervalValue && this.intervalValue > 0) {
      this.timer = setInterval(this.reload.bind(this), this.intervalValue);
    }
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  reload() {
    PageLoader.load(window.location.href, PageLoader.Action.IGNORE);
  }
}

import merge from "lodash.merge";

const defaultConfig = {
  render: {
    option_create: (data, escape) => {
      return (
        '<div class="create">Lägg till <strong>' +
        escape(data.input) +
        "</strong>&hellip;</div>"
      );
    },
    no_results: () => {
      return '<div class="no-results">Inga resultat hittades</div>';
    },
  },
  onInitialize: () => {
    // Fix styling of clear_button plugin
    document.querySelectorAll(".plugin-clear_button").forEach((item) => {
      item.classList.remove("plugin-clear_button");
    });
  },
};

const defaultConfigTags = merge(
  {},
  { ...defaultConfig },
  {
    create: false,
    delimiter: null,
    controlClass: "chip-container mt-16",
    closeAfterSelect: true,
    plugins: {
      remove_button: {
        label: '<i class="fa fa-close"></i>',
        title: "Ta bort",
      },
    },
    preload: "focus",
    render: {
      item: (data, escape) => {
        return '<span class="chip">' + escape(data.text) + "</span>";
      },
      no_results: null,
    },
    onInitialize: () => {
      // Fix styling of remove_button plugin
      document.querySelectorAll(".plugin-remove_button").forEach((item) => {
        item.classList.remove("plugin-remove_button");
      });
    },
  }
);

export { defaultConfig, defaultConfigTags };
